import { toast } from "react-toastify";
import { customAxios } from "src/api/api";
import { AuthedUser } from "./models";
import { setToken } from "src/api/token";

interface ApiResponse<T> {
  data: T;
  hasError: boolean;
}

export const loginUser = async (email: string, password: string): Promise<ApiResponse<AuthedUser | undefined>> => {
  let url = `/login`;
  try {
    const response = await customAxios.post(url, { email, password });
    if (response.status === 401) {
      //toast.error("Incorrect credentials used.");
      return { data: undefined, hasError: true };
    }
    setToken(response.data.token);

    return { data: response.data, hasError: false };
  } catch (err) {
    toast.error("Failed to login");
  }
  return { data: undefined, hasError: true };
};
