import { useState, PropsWithChildren } from "react";
import {
  Button,
  Card,
  CardHeader,
  CardText,
  CardTitle,
  Col,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "reactstrap";
import { OnboardProgress } from "../models";

export const RenderStrikeThrough = (props: { isStrikeThrough: boolean; children: JSX.Element }) => {
  if (props.isStrikeThrough) {
    return <del>{props.children}</del>;
  }
  return props.children;
};

function OnboardingWidget(props: { onboardStatus: OnboardProgress }) {
  return (
    <>
      <Row>
        <Col>
          <Card body style={{ marginBottom: "20px" }}>
            <RenderStrikeThrough isStrikeThrough={props.onboardStatus.isCompanyProfileComplete}>
              <CardTitle tag="h5">#1 Company Profile Information</CardTitle>
            </RenderStrikeThrough>
            <RenderStrikeThrough isStrikeThrough={props.onboardStatus.isCompanyProfileComplete}>
              <CardText>
                <a href="/profile/company">Fill out company contact information</a> that will be shown to your customers
                on the estimate portal.
              </CardText>
            </RenderStrikeThrough>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col>
          <Card body>
            <RenderStrikeThrough isStrikeThrough={props.onboardStatus.isCatalogComplete}>
              <CardTitle tag="h5">#2 Fence Catalog</CardTitle>
            </RenderStrikeThrough>
            <RenderStrikeThrough isStrikeThrough={props.onboardStatus.isCatalogComplete}>
              <CardText>
                <a href="/catalog">Configure your catalog</a> with styles, colors, heights and prices. Catalog is what
                your offer to your customers.
              </CardText>
            </RenderStrikeThrough>
          </Card>
        </Col>
      </Row>
    </>
  );
}

export function OnboardCTA(props: { isOpen: boolean; onboardStatus: OnboardProgress }) {
  const [isOpen, setOpen] = useState(props.isOpen);
  function handleDismiss(event: React.MouseEvent<HTMLButtonElement, MouseEvent>): void {
    setOpen(false);
  }

  return (
    <>
      <Modal isOpen={isOpen}>
        <ModalHeader>Complete Your Onboarding</ModalHeader>
        <ModalBody>
          <OnboardingWidget onboardStatus={props.onboardStatus} />
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" outline onClick={handleDismiss}>
            Dismiss
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
}
