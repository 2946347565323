import { Button, ButtonProps, Spinner } from "reactstrap";

interface SpinnerButtonProps extends ButtonProps {
  isloading: boolean;
  text: string;
  spinnerText?: string;
}

export function SpinnerButton(props: SpinnerButtonProps) {
  //let spinnerLabel = "Saving";
  //if (props.spinnerText!==undefined) spinnerLabel=props.
  return (
    <Button {...props}>
      {props.isloading ? <Spinner size="sm">{props.spinnerText ?? "Saving"}...</Spinner> : ""}
      {props.isloading ? props.spinnerText ?? "Saving" : props.text}
    </Button>
  );
}
