import { Row, Col, Card, CardHeader, CardBody } from "reactstrap";
import { UserPasswordUpdateForm } from "./userPasswordUpdateForm";
import { UserInformationForm } from "./userInformationForm";

export function UserSettingsPage() {
  return (
    <>
      <Row className="justify-content-center">
        <Col md={6}>
          <Card className="my-2">
            <CardHeader>User Profile</CardHeader>
            <CardBody>
              <UserInformationForm />
            </CardBody>
          </Card>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col md={6}>
          <Card className="my-2">
            <CardHeader>Password Update</CardHeader>
            <CardBody>
              <UserPasswordUpdateForm />
            </CardBody>
          </Card>
        </Col>
      </Row>
    </>
  );
}
