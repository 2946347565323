import * as React from "react";
import Header from "./Header";
import Footer from "./Footer";
import { Navigate, useNavigate } from "react-router";
import { getUser, isUserAuthenticated } from "src/auth";
import { signOut } from "src/api/token";
import { Container } from "reactstrap";
import { ToastContainer } from "react-toastify";

import "react-toastify/dist/ReactToastify.css";
type Props = {
  children: JSX.Element[] | JSX.Element;
};

export default function Layout(props: Props) {
  const navigate = useNavigate();
  const returnUrl = window.location.pathname;
  let loginUrl = `/login?returnUrl=${returnUrl}`;
  if (returnUrl === "/") {
    loginUrl = "/login";
  }

  if (!isUserAuthenticated()) return <Navigate to={loginUrl} />;

  const handleSignout = () => {
    signOut();
    return navigate("/");
  };

  return (
    <>
      <Header user={getUser()} singout={handleSignout} />
      <Container fluid>
        <main>{props.children}</main>
        <Footer />
        <ToastContainer />
      </Container>
    </>
  );
}
