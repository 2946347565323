import { Card, CardBody, CardHeader, Col, Row } from "reactstrap";
import { EstimatePortalSettingsForm } from "./estimatePortalSettingsForm";

export function PortalSettingsPage() {
  return (
    <>
      <Row className="justify-content-center">
        <Col>
          <Card className="my-2">
            <CardHeader>Customer Portal Settings</CardHeader>
            <CardBody>
              <EstimatePortalSettingsForm />
            </CardBody>
          </Card>
        </Col>
      </Row>
    </>
  );
}
