import { customAxios } from "src/api/api";
import { FenceVariantDetails, FenceVariants } from "./models";
import { convertFromGetStyleVariantsResponse, convertFromVariantDetails } from "./transforms";
import { toast } from "react-toastify";
import { BadResponse } from "./apiContracts";
import { AxiosError } from "axios";

interface ApiResponse<T> {
  data: T | undefined;
  hasError: boolean;
  hasValidationError: boolean;
}

export const getFenceVariants = async (fenceTypeId: string, styleId: string): Promise<ApiResponse<FenceVariants>> => {
  const url = `/api/v1/catalog/types/${fenceTypeId}/styles/${styleId}`;
  try {
    const response = await customAxios.get(url);
    return { data: convertFromGetStyleVariantsResponse(response.data), hasError: false, hasValidationError: false };
  } catch (err) {
    toast.error("Failed to load fence variants.");
    console.log(err);
  }
  return { data: undefined, hasError: true, hasValidationError: false };
};

export const createVariant = async (
  typeId: string,
  styleId: string,
  newVariant: FenceVariantDetails
): Promise<ApiResponse<BadResponse | undefined>> => {
  const url = `/api/v1/catalog/types/${typeId}/styles/${styleId}/variants`;

  try {
    const response = await customAxios.post(url, convertFromVariantDetails(newVariant));
    if (response.status === 400) {
      //validation errors
      var d = response.data as BadResponse;
      if (d.areValidationErrors) {
        return { data: d, hasError: false, hasValidationError: true };
      } else {
        throw AxiosError;
      }
    }
    return { data: undefined, hasError: false, hasValidationError: false };
  } catch (err) {
    toast.error("Failed to create a new fence variant.");
    console.log(err);
  }
  return { data: undefined, hasError: true, hasValidationError: false };
};

export const deleteVariant = async (
  typeId: string,
  styleId: string,
  variantId: string
): Promise<ApiResponse<{ isSuccess: boolean } | undefined>> => {
  const url = `api/v1/catalog/types/${typeId}/styles/${styleId}/variants/${variantId}`;

  try {
    const response = await customAxios.delete(url);
    return { data: { isSuccess: true }, hasError: false, hasValidationError: false };
  } catch (err) {
    toast.error("Failed to delete fence variant.");
    console.log(err);
  }
  return { data: undefined, hasError: true, hasValidationError: false };
};

export const updateStyle = async (
  typeId: string,
  styleId: string,
  updatedStyle: {}
): Promise<ApiResponse<{ isSuccess: boolean } | undefined>> => {
  const url = `api/v1/catalog/types/${typeId}/styles/${styleId}`;

  try {
    const response = await customAxios.put(url, updatedStyle);
    return { data: { isSuccess: true }, hasError: false, hasValidationError: false };
  } catch (err) {
    toast.error("Failed to update fence style.");
    console.log(err);
  }
  return { data: undefined, hasError: true, hasValidationError: false };
};

export const deleteStyleImageServiceCall = async (typeId: string, styleId: string): Promise<ApiResponse<undefined>> => {
  const url = `api/v1/catalog/types/${typeId}/styles/${styleId}/image`;

  try {
    const response = await customAxios.delete(url);
    toast.done("Fence Style image deleted.");
    return { data: undefined, hasError: false, hasValidationError: false };
  } catch (err) {
    toast.error("Failed to delete image.");
    console.log(err);
  }
  return { data: undefined, hasError: true, hasValidationError: false };
};

export const updateStyleImage = async (typeId: string, styleId: string, data: {}): Promise<ApiResponse<undefined>> => {
  const url = `api/v1/catalog/types/${typeId}/styles/${styleId}/image`;

  try {
    const response = await customAxios.post(url, data);
    toast.done("Fence Style image updated.");
    return { data: undefined, hasError: false, hasValidationError: false };
  } catch (err) {
    toast.error("Failed to delete image.");
    console.log(err);
  }
  return { data: undefined, hasError: true, hasValidationError: false };
};
