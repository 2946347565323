import { Col } from "reactstrap";
import { ISegment } from "../models";
import { formatCurrency } from "src/utils";

type Props = {
  segmentNumber: number;
  segmentDetails: ISegment;
};

const gateFormatter = (numOfSingleGate: number, numOfDoubleGate: number) => {
  const single = numOfSingleGate > 0 ? `${numOfSingleGate} Single` : "";
  const double = numOfDoubleGate > 0 ? `${numOfDoubleGate} Double` : "";
  if (single === "" && double === "") {
    return "--";
  }

  return (
    <>
      {single}
      <br />
      {double}
      <br />
    </>
  );
};
const FenceSegmentSummary = function (props: Props) {
  return (
    <Col md={4}>
      <table className="table">
        <thead className=" text-primary">
          <tr>
            <th>Fence #{props.segmentNumber}</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <strong>Type:</strong>
            </td>
            <td>{props.segmentDetails.type}</td>
          </tr>
          <tr>
            <td>
              <strong>Style:</strong>
            </td>
            <td>{props.segmentDetails.style}</td>
          </tr>
          <tr>
            <td>
              <strong>Color:</strong>
            </td>
            <td>{props.segmentDetails.color}</td>
          </tr>
          <tr>
            <td>
              <strong>Height:</strong>
            </td>
            <td>{props.segmentDetails.height}</td>
          </tr>
          <tr>
            <td>
              <strong>Length:</strong>
            </td>
            <td>{props.segmentDetails.calculatedLength} LFT</td>
          </tr>
          <tr>
            <td>
              <strong>Gates:</strong>
            </td>
            <td>{gateFormatter(props.segmentDetails.singleGateCount, props.segmentDetails.doubleGateCount)}</td>
          </tr>
        </tbody>
        <tfoot>
          <tr>
            <td>
              <strong>Segment Total:</strong>
            </td>
            <td>
              {formatCurrency(props.segmentDetails.totalRange.min)} -{" "}
              {formatCurrency(props.segmentDetails.totalRange.max)}
            </td>
          </tr>
        </tfoot>
      </table>
    </Col>
  );
};

export default FenceSegmentSummary;
