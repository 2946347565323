import { Form } from "react-router-dom";
import { FormGroup, Label, Input } from "reactstrap";
import { SpinnerButton } from "src/components/SpinnerButton";
import { useState } from "react";
import { toast } from "react-toastify";
import { useSaveUserPasswords } from "./useHooks";

export function UserPasswordUpdateForm() {
  const [formPasswords, setPasswords] = useState({
    password: "",
    confirmPassword: "",
  });

  const { saveUserPassword, hasError: hasPasswordError, isSaving } = useSaveUserPasswords();

  function handlePasswordChange(event: React.ChangeEvent<HTMLInputElement>): void {
    formPasswords.password = event.currentTarget.value;
    setPasswords({ ...formPasswords });
  }
  function handlePasswordConfirmChange(event: React.ChangeEvent<HTMLInputElement>): void {
    formPasswords.confirmPassword = event.currentTarget.value;
    setPasswords({ ...formPasswords });
  }

  function handleFormSubmit(event: React.FormEvent<HTMLFormElement>): void {
    if (formPasswords.password !== formPasswords.confirmPassword) {
      toast.warn("Passwords must match");
      return;
    }
    if (formPasswords.confirmPassword !== "") {
      saveUserPassword(formPasswords.confirmPassword).then((r) => {
        if (!hasPasswordError) {
          setPasswords({ password: "", confirmPassword: "" });
        }
      });
    }
  }

  return (
    <Form onSubmit={handleFormSubmit}>
      <FormGroup>
        <Label for="password">Password</Label>
        <Input
          id="password"
          name="password"
          type="password"
          value={formPasswords.password}
          onChange={handlePasswordChange}
        />
      </FormGroup>
      <FormGroup>
        <Label for="passwordConfirm">Confirm Password </Label>
        <Input
          id="passwordConfirm"
          name="passwordConfirm"
          type="password"
          value={formPasswords.confirmPassword}
          onChange={handlePasswordConfirmChange}
        />
      </FormGroup>
      <SpinnerButton text={"Update"} isloading={isSaving} type="submit" />
    </Form>
  );
}
