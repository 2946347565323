import * as React from "react";
import { Button, Table } from "reactstrap";
import { EstimateSummary } from "./models";
import { useNavigate } from "react-router";

export interface Quote {
  id: string;
  date: string;
  types: string;
  linearFeet: number;
  customerName: string;
  customerAddressStreet: string;
  customerAddressCity: string;
  customerAddressState: string;
  customerAddressZip: string;
  customerEmail: string;
  customerPhone: string;
  price: number;
  singleGateCount: number;
  doubleGateCount: number;
}

function CustomerInfo(props: {
  customerName: string;
  customerAddressStreet: string;
  customerAddressCity: string;
  customerAddressState: string;
  customerAddressZip: string;
  customerEmail: string;
  customerPhone: string;
  preferredMethodOfContact: string;
}) {
  const mailTo = `mailto:${props.customerEmail}`;
  const tel = `tel:${props.customerPhone}`;
  return (
    <div>
      <span>{props.customerName}</span>
      <address>
        {props.customerAddressStreet}
        <br />
        {props.customerAddressCity}, {props.customerAddressState}, {props.customerAddressZip}
      </address>
      <span>
        Phone: <a href={tel}>{props.customerPhone}</a>
      </span>
      <br />
      <span>
        Email: <a href={mailTo}>{props.customerEmail}</a>
      </span>
      <br />
      <span>Preferred Method of Contact: {props.preferredMethodOfContact}</span>
    </div>
  );
}

function GateInfo(props: { singleGateCount: number; doubleGateCount: number }) {
  let singleGateMsg;
  let msg;
  if (props.singleGateCount > 0) {
    singleGateMsg = `${props.singleGateCount} Single Width Gate(s)`;
    msg = <>{singleGateMsg}</>;
  }
  let doubleGateMsg;
  if (props.doubleGateCount > 0) {
    doubleGateMsg = `${props.doubleGateCount} Double Width Gate(s)`;
    if (props.singleGateCount > 0) {
      msg = (
        <>
          {singleGateMsg} <br />
          {doubleGateMsg}
        </>
      );
    }
  }

  return <>{msg}</>;
}

type EstimatesTableRowProps = {
  estimate: EstimateSummary;
};
function EstimatesTableRow(props: EstimatesTableRowProps) {
  const navigate = useNavigate();
  const e = props.estimate;

  function handleGoToDetails(event: React.MouseEvent<HTMLButtonElement, MouseEvent>): void {
    navigate(`/estimates/${props.estimate.id}`);
  }

  return (
    <>
      <tr key={props.estimate.id}>
        <td>{e.date}</td>
        <td>{e.summary}</td>
        <td>
          <CustomerInfo
            customerAddressStreet={e.customerAddressStreet}
            customerAddressCity={e.customerAddressCity}
            customerAddressState={e.customerAddressState}
            customerAddressZip={e.customerAddressZip}
            customerEmail={e.customerEmail}
            customerName={e.customerName}
            customerPhone={e.customerPhone}
            preferredMethodOfContact={e.customerPreferredMethodOfContact}
          />
        </td>
        <td>{e.linearFeet} FLT</td>
        <td>
          <GateInfo singleGateCount={e.singleGateCount} doubleGateCount={e.doubleGateCount} />
        </td>
        <td>{e.formattedPrice}</td>
        <td>
          <Button outline onClick={handleGoToDetails}>
            Details
          </Button>
        </td>
      </tr>
    </>
  );
}

type EstimateTableProps = {
  estimates: EstimateSummary[];
};
export default function EstimatesTable(props: EstimateTableProps) {
  return (
    <>
      <Table responsive>
        <thead>
          <tr key={"header"}>
            <th>Date</th>
            <th>Summary</th>
            <th>Customer</th>
            <th>Linear Feet (LFT)</th>
            <th>Gates</th>
            <th>Price</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {props.estimates.map((q, i) => (
            <EstimatesTableRow key={q.id} estimate={q} />
          ))}
        </tbody>
      </Table>
    </>
  );
}
