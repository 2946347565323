import React from "react";
import { toast } from "react-toastify";
import { Button, Col, FormGroup, Input, Row } from "reactstrap";
import { useGetPortalSettings } from "./useHooks";

export function EstimatePortalSettingsForm() {
  const { data } = useGetPortalSettings();

  function handleCopyUrl(event: React.MouseEvent<HTMLButtonElement>) {
    event.stopPropagation();
    if (event.currentTarget.name === "portalUrl") {
      navigator.clipboard.writeText(data.portalUrl);
      toast.success("Portal url copied to clipboard!");
    } else {
      navigator.clipboard.writeText(data.portalUrl);
      toast.success("Embedd javascript copied to clipboard!");
    }
  }
  return (
    <>
      <Row>
        <Col md={8}>
          <p>
            Copy the url below and share it with your customers. Share it on Facebook/Twitter, add it to your Instagram
            profile. Anywhere where your customers are looking to find your business.
          </p>
        </Col>
      </Row>
      <Row>
        <Col xs={12} md={6}>
          <Input value={data.portalUrl} style={{ marginBottom: "10px" }} disabled />
        </Col>

        <Col xs={12} md={6}>
          <Row>
            <Col xs={6} md={6}>
              <Button name="portalUrl" onClick={handleCopyUrl}>
                Copy to clipboard
              </Button>
            </Col>
            <Col xs={6} md={6}>
              <a
                target="_blank"
                className="btn btn-outline-secondary"
                style={{ marginLeft: "10px" }}
                href={data.portalUrl + "?dnt=true"}
                rel="noreferrer"
              >
                Go To Estimate Portal
              </a>
            </Col>
          </Row>
        </Col>
        <Col></Col>
      </Row>
      <hr />
      <Row>
        <Col md={6}>
          <p>
            The script below should be added to the head section of your website layout. It will create a "Free Online
            Estimate" button on your company website. Please reach out to use if you don't know how to add it.
          </p>
        </Col>
      </Row>
      <Row>
        <Col xs={12} md={6}>
          <Input type="textarea" value={data.embeddJs} disabled />
        </Col>
        <Col xs={12} md={6}>
          <Button name="jsEmbedd" onClick={handleCopyUrl}>
            Copy to clipboard
          </Button>
        </Col>
      </Row>
    </>
  );
}
