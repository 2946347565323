import { useState, useEffect } from "react";
import { CompanyInformation, EmptyCompanyInformation } from "./models";
import { getCompanyInformation, saveCompanyInformation } from "./service";

export function useGetCompanyInformation() {
  const [isLoading, setLoading] = useState(false);
  const [data, setItems] = useState<CompanyInformation>(EmptyCompanyInformation);
  const [hasError, setError] = useState(false);
  const [refresh, setRefresh] = useState(false);

  useEffect(() => {
    setLoading(true);
    getCompanyInformation().then((r) => {
      if (!r.hasError) {
        setItems(r.data!);
      }
      setError(r.hasError);
      setLoading(false);
    });
  }, [refresh]);

  const refreshCompanyProfile = () => {
    setRefresh(!refresh);
  };

  return { data, setItems, isLoading, hasError, refreshCompanyProfile };
}

export function useSubmitCompanyProfile() {
  const [isSaving, setSaving] = useState(false);
  const [hasError, setError] = useState(false);

  const submitCompanyProfile = (companyProfile: CompanyInformation) => {
    setSaving(true); // Adding loading state
    return saveCompanyInformation(companyProfile).then((r) => {
      setSaving(false); // Removing loading state
      setError(r.hasError);
      return r;
    });
  };
  return { submitCompanyProfile, hasError, isSaving };
}
