import { createBrowserRouter } from "react-router-dom";
import {
  DashboardPage,
  LoginPage,
  ErrorPage,
  Layout,
  CatalogPage,
  CatalogVariantsPage,
  EstimatesListPage,
  EstimateDetailPage,
  ProfilePage,
  RegisterPage,
  UserSettingsPage,
  PortalSettingsPage,
} from "src/pages";

const router = createBrowserRouter([
  {
    path: "/",
    element: (
      <Layout>
        <DashboardPage />
      </Layout>
    ),
    errorElement: <ErrorPage />,
  },
  {
    path: "/estimates",
    element: (
      <Layout>
        <EstimatesListPage />
      </Layout>
    ),
  },
  {
    path: "/estimates/:id",
    element: (
      <Layout>
        <EstimateDetailPage />
      </Layout>
    ),
  },
  {
    path: "/catalog",
    element: (
      <Layout>
        <CatalogPage />
      </Layout>
    ),
    errorElement: <ErrorPage />,
  },
  {
    path: "/catalog/:type/:typeid/style/:id",
    element: (
      <Layout>
        <CatalogVariantsPage />
      </Layout>
    ),
    errorElement: <ErrorPage />,
  },
  {
    path: "/profile/company",
    element: (
      <Layout>
        <ProfilePage />
      </Layout>
    ),
    errorElement: <ErrorPage />,
  },
  {
    path: "/settings/user",
    element: (
      <Layout>
        <UserSettingsPage />
      </Layout>
    ),
    errorElement: <ErrorPage />,
  },
  {
    path: "/settings/portal",
    element: (
      <Layout>
        <PortalSettingsPage />
      </Layout>
    ),
    errorElement: <ErrorPage />,
  },
  {
    path: "/login",
    element: <LoginPage />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/register",
    element: <RegisterPage />,
    errorElement: <ErrorPage />,
  },
]);

export default router;
