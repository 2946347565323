import { jwtDecode } from "jwt-decode";

export const fakeTOKEN = `eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiIxMjM0NTY3ODkwIiwibmFtZSI6IkpvaG4gRG9lIiwiaWF0IjoxNTE2MjM5MDIyLCJleHAiOjE4MTYyMzkwMjIsImNvbXBhbnlJZCI6IjIzNDIzNDQzMjMiLCJyb2xlIjoiQURNSU4iLCJpc09uYm9hcmRlZCI6InRydWUifQ.k_5Ana4mux-diE8Pw5jLP9u2GTz494aFU08QZK-31ec`;

interface ITokenDto {
  exp: number;
  iat: number;
  oid: string;
  name: string;
  tid: string;
  role: string;
  isOnboarded: string;
}

//class TokenManager {
export function setToken(idToken: string) {
  localStorage.setItem("id_token", idToken);
}

export function isTokenExpired(token: ITokenDto) {
  try {
    if (token.exp < Date.now() / 1000) {
      // Checking if token is expired. N
      return true;
    } else {
      return false;
    }
  } catch (err) {
    return false;
  }
}

export function getDecodedToken(): ITokenDto | undefined {
  // Retrieves the user token from localStorage
  const strToken = localStorage.getItem("id_token");
  if (strToken === null) {
    return undefined;
  }
  const decoded = jwtDecode<ITokenDto>(strToken);
  return decoded;
}

export function getToken() {
  const strToken = localStorage.getItem("id_token");
  return strToken;
}

export function signOut(): void {
  // Clear access token and ID token from local storage
  localStorage.removeItem("id_token");
  localStorage.removeItem("fullName");
}
