import * as React from "react";
import { useState } from "react";
import { Card, CardHeader, CardBody } from "reactstrap";
import StyleTable from "./styles-table";
import { useCreateNewStyle, useDeleteStyle } from "../useHooks";
import FenceTypeDisabledForm from "./fenceTypeDisabledForm";
import AddNewFenceStyleForm from "./addNewFenceStyleForm";
import { NoStyles } from "./NoStyles";
import { FenceType } from "../models";
import { toast } from "react-toastify";

type TypeCardProps = {
  fenceType: FenceType;
  refresh: () => void;
};

export default function TypeCard(props: TypeCardProps) {
  //const { data, hasError, refreshItems } = useGetFenceStyleList(props.fenceTypeId);

  const { deleteStyle, hasError: hasErrorDeleting } = useDeleteStyle();

  /* function handleAddStyleForm(styleName: string): void {
    //if unique
    if (data.some((x) => x.name.toUpperCase() === styleName.toUpperCase().trim())) {
      return;
    }

    createStyle(props.fenceTypeId, styleName.trim()).then(() => {
      //setStyleInput({ value: "", isValid: undefined });
      refreshItems();
    });
  }*/

  function handleDelete(styleId: string): void {
    deleteStyle(props.fenceType.id, styleId).then((r) => {
      if (r.data?.isSuccess) {
        toast.success("Style deleted");
        props.refresh();
      }
    });
  }

  return (
    <Card className="my-2" color="primary" outline body>
      <CardHeader>
        <FenceTypeDisabledForm
          fenceTypeName={props.fenceType.name}
          isDisabled={props.fenceType.isDisabled}
          fenceTypeId={props.fenceType.id}
        />
      </CardHeader>
      <CardBody>
        <StyleTable
          styles={props.fenceType.styleSummaries}
          typeId={props.fenceType.id}
          typeName={props.fenceType.name}
          onDelete={handleDelete}
        />
        <NoStyles display={props.fenceType.availableColors.length === 0} />
        <AddNewFenceStyleForm
          availableColors={props.fenceType.availableColors}
          configuredStylesNames={props.fenceType.styleSummaries.map((x) => x.name)}
          reloadStyles={props.refresh}
          fenceTypeId={props.fenceType.id}
        />
      </CardBody>
    </Card>
  );
}
