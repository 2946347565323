import { Col, Form, FormGroup, Input, Label, Row } from "reactstrap";
import Select, { MultiValue, ActionMeta } from "react-select";
import { FormEvent, useState } from "react";
import { SpinnerButton } from "src/components/SpinnerButton";

type UpdateStyleFormProps = {
  name: string;
  onFormSubmitSuccess: (styleName: string, selectedColors: { label: string; value: any }[]) => void;
  selectedColors: { label: string; value: any }[];
  availableColors: { label: string; value: any }[];
  isLoading: boolean;
};

export function UpdateStyleForm(props: UpdateStyleFormProps) {
  const [styleName, setStyleName] = useState(props.name);
  const [colors, setColors] = useState(props.selectedColors);

  function handleOption(
    newValue: MultiValue<{ label: string; value: number }>,
    actionMeta: ActionMeta<{ label: string; value: number }>
  ): void {
    setColors(
      newValue.map((x) => {
        return { label: x.label, value: x.value };
      })
    );
  }

  function handleSubmit(event: FormEvent<HTMLFormElement>): void {
    event.stopPropagation();
    event.preventDefault();

    if (colors.length === 0) {
      return;
    }

    props.onFormSubmitSuccess(styleName, colors);
  }

  let customStyles = undefined;
  if (colors.length === 0) {
    customStyles = {
      control: (provided: any) => ({
        ...provided,
        borderColor: "red",
      }),
    };
  }
  return (
    <>
      <Form onSubmit={handleSubmit}>
        <Row>
          <Col>
            <FormGroup>
              <Label for="examplePassword">Style</Label>
              <Input
                value={styleName}
                required
                onChange={(e) => {
                  setStyleName(e.currentTarget.value);
                }}
              />
            </FormGroup>
          </Col>
          <Col>
            <FormGroup>
              <Label for="colors">Colors</Label>
              <Select
                isMulti
                options={props.availableColors || []}
                value={colors || []}
                onChange={handleOption}
                styles={customStyles}
              />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col>
            <SpinnerButton type="submit" text="Update" isloading={props.isLoading} />
          </Col>
        </Row>
      </Form>
    </>
  );
}
