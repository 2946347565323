import { useDeleteStyleImage } from "./useHooks";
import { Row, Col, Button } from "reactstrap";

export function CurrentImageView(props: {
  typeId: string;
  styleId: string;
  uuid: string;
  cdnUrl: string;
  refresh: () => void;
}) {
  const { deleteStyleImage, hasError } = useDeleteStyleImage();

  function handleRemoveClick(e: React.MouseEvent<HTMLButtonElement>) {
    e.preventDefault();
    deleteStyleImage(props.typeId, props.styleId).then((r) => {
      if (!hasError) {
        props.refresh();
      }
    });
  }
  return (
    <>
      <Row>
        <Col>
          <div key={props.uuid} style={{ marginTop: "20px" }}>
            <img
              className="rounded mx-auto d-block"
              key={props.uuid}
              src={`${props.cdnUrl}/-/preview/-/resize/x400/`}
              width="200"
              height="200"
              alt={"Fence Style"}
              title={"Fence Style"}
            />
          </div>
        </Col>
      </Row>
      <Row>
        <Col className="d-flex align-items-center justify-content-center" style={{ paddingTop: "20px" }}>
          <Button color="danger" onClick={handleRemoveClick} outline>
            Delete Image
          </Button>
        </Col>
      </Row>
    </>
  );
}
