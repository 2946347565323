import { useState } from "react";
import { loginUser } from "./service";

export function useLogin() {
  const [isLoading, setLoading] = useState(false);
  const [hasError, setError] = useState(false);

  const login = (email: string, password: string) => {
    setLoading(true); // Adding loading state
    return loginUser(email, password).then((r) => {
      setLoading(false); // Removing loading state
      if (r.data === undefined) {
        setError(true);
      }
      return r;
    });
  };
  return { login, hasError, isLoading };
}
