import { useEffect, useState } from "react";
import {
  getFenceVariants,
  createVariant as createVariantServiceCall,
  deleteVariant as deleteVariantServiceCall,
  updateStyle as updateStyleServiceCall,
  deleteStyleImageServiceCall,
  updateStyleImage as updateStyleImageServiceCall,
} from "./service";
import { FenceVariantDetails, FenceVariants } from "./models";

export function useGetFenceVariantList(fenceTypeId: string, styleId: string) {
  const [isLoading, setLoading] = useState(false);
  const [data, setItems] = useState<FenceVariants>();
  const [refresh, setRefresh] = useState(false);
  const [hasError, setError] = useState(false);

  useEffect(() => {
    getFenceVariants(fenceTypeId, styleId).then((f) => {
      setItems(f.data);
      setError(f.hasError);
    });
  }, [refresh]);

  const refreshItems = () => {
    setRefresh(!refresh);
  };

  return { data, hasError, refreshItems };
}

export const useCreateNewVariant = () => {
  const [hasError, setError] = useState(false);
  const [isCreateNewVariantLoading, setLoading] = useState(false);

  const createVariant = (fenceTypeId: string, styleId: string, newVariant: FenceVariantDetails) => {
    setLoading(true); // Adding loading state
    return createVariantServiceCall(fenceTypeId, styleId, newVariant).then((r) => {
      setLoading(false); // Removing loading state
      setError(r.hasError);
      return r;
    });
  };

  return { createVariant, isCreateNewVariantLoading, hasError };
};

export const useDeleteVariant = () => {
  const [hasError, setError] = useState(false);
  const [isLoading, setLoading] = useState(false);

  const deleteVariant = (fenceTypeId: string, styleId: string, variantId: string) => {
    setLoading(true); // Adding loading state
    return deleteVariantServiceCall(fenceTypeId, styleId, variantId).then((r) => {
      setLoading(false); // Removing loading state
      setError(r.hasError);
      return r;
    });
  };

  return { deleteVariant, hasError };
};

export const useUpdateStyleDetails = () => {
  const [hasError, setError] = useState(false);
  const [isUpdateStyleLoading, setLoading] = useState(false);

  const updateStyle = (
    typeId: string,
    styleId: string,
    name: string,
    colorIds: string[],
    image?: {
      uuid: string;
      cdnUrl: string;
    }
  ) => {
    setLoading(true); // Adding loading state
    return updateStyleServiceCall(typeId, styleId, {
      name: name,
      colorIds: colorIds,
      image: image,
    }).then((r) => {
      setLoading(false); // Removing loading state
      setError(r.hasError);
      return r;
    });
  };

  return { updateStyle, isUpdateStyleLoading, hasError };
};

export const useDeleteStyleImage = () => {
  const [hasError, setError] = useState(false);
  const [isLoading, setLoading] = useState(false);

  const deleteStyleImage = (typeId: string, styleId: string) => {
    setLoading(true); // Adding loading state
    return deleteStyleImageServiceCall(typeId, styleId).then((r) => {
      setLoading(false); // Removing loading state
      setError(r.hasError);
    });
  };

  return { deleteStyleImage, hasError };
};

export const useUpdateStyleImage = () => {
  const [hasError, setError] = useState(false);
  const [isLoading, setLoading] = useState(false);

  const updateStyleImage = (typeId: string, styleId: string, data: {}) => {
    setLoading(true); // Adding loading state
    return updateStyleImageServiceCall(typeId, styleId, data).then((r) => {
      setLoading(false); // Removing loading state
      setError(r.hasError);
      return r;
    });
  };
  return { updateStyleImage, hasError };
};
