import * as React from "react";
import { Pagination, PaginationItem, PaginationLink } from "reactstrap";

type EstimatesTablePaginationProps = {
  currentPage: number;
  totalCount: number;
  pageSize: number;
  totalPages: number;
  onGoToPage: (page: number) => void;
};

export default function EstimatesTablePagination(props: EstimatesTablePaginationProps) {
  const totalPages = props.totalPages;
  //const totalPages = Math.floor(props.totalCount / props.pageSize);
  const isFirstDisabled = props.currentPage === 1;
  const isNextDisabled = props.currentPage === totalPages;
  const isLastDisabled = totalPages === props.currentPage;

  const currentBlock = Math.floor(props.currentPage / 5);
  const currentBlockStartNumber = Math.max(currentBlock * 5, 1);
  const currentBlockEndNumber = Math.min(totalPages, currentBlockStartNumber + 4);

  console.log(props.totalCount);
  console.log(props.pageSize);

  if (props.totalCount <= props.pageSize) return <></>;

  const handlePageClick = (page: number) => (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    // param is the argument you passed to the function
    // e is the event object that returned
    props.onGoToPage(page);
  };

  let items = [];
  for (let index = currentBlockStartNumber; index <= currentBlockEndNumber; index++) {
    items.push(
      <PaginationItem key={index} active={index === props.currentPage}>
        <PaginationLink href="#" onClick={handlePageClick(index)}>
          {index}
        </PaginationLink>
      </PaginationItem>
    );
  }

  return (
    <div
      style={{
        display: "block",
        width: 700,
        padding: 30,
      }}
    >
      {" "}
      <Pagination>
        <PaginationItem disabled={isFirstDisabled}>
          <PaginationLink onClick={handlePageClick(1)} first href="#" />
        </PaginationItem>
        <PaginationItem disabled={isFirstDisabled}>
          <PaginationLink onClick={handlePageClick(props.currentPage - 1)} href="#" previous />
        </PaginationItem>
        {items.map((p, i) => p)}
        <PaginationItem disabled={isNextDisabled}>
          <PaginationLink onClick={handlePageClick(props.currentPage + 1)} href="#" next />
        </PaginationItem>
        <PaginationItem disabled={isLastDisabled}>
          <PaginationLink onClick={handlePageClick(totalPages)} href="#" last />
        </PaginationItem>
      </Pagination>
    </div>
  );
}
