import * as React from "react";
import { OnboardCTA } from "./components/Onboarding";
import { Row, Col } from "reactstrap";
import { useGetOnboardingStatus } from "./useHooks";
import { useNavigate } from "react-router";
import { useEffect } from "react";

export default function DashboardPage() {
  const navigate = useNavigate();
  const { onboardingStatus, hasError } = useGetOnboardingStatus();

  useEffect(() => {
    if (onboardingStatus.isFullyOnboarded || hasError) {
      navigate("/estimates");
    }
  }, [onboardingStatus.isFullyOnboarded, hasError, navigate]);

  return (
    <>
      <OnboardCTA isOpen={true} onboardStatus={onboardingStatus} />

      <Row>
        <Col>
          <div
            className="d-flex align-items-center justify-content-center"
            style={{ height: "400px", width: "100%", marginBottom: "20px" }}
          ></div>
        </Col>
      </Row>
    </>
  );
}
