import { EstimateListResponse, EstimateDetailsResponse } from "./apiContracts";
import { EstimateSummary, IEstimateDetails } from "./models";

export function convertEstimateListfrom(from: EstimateListResponse): {
  estimates: Array<EstimateSummary>;
  totalPages: number;
  pageNumber: number;
  pageSize: number;
  total: number;
} {
  const to: Array<EstimateSummary> = [];

  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",

    // These options are needed to round to whole numbers if that's what you want.
    //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
    maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
  });

  const totalPages = from.totalPages;
  const pageNumber = from.pageNumber;
  const pageSize = from.pageSize;
  const total = from.total;
  from.estimates.forEach((f) => {
    to.push({
      id: f.id,
      date: new Date(f.createdDate).toLocaleString(),
      summary: f.fenceDescription,
      linearFeet: f.linearFeet,
      customerName: f.customer.name,
      customerAddressStreet: f.worksite.street,
      customerAddressCity: f.worksite.city,
      customerAddressState: f.worksite.state,
      customerAddressZip: f.worksite.zipcode,
      customerEmail: f.customer.email,
      customerPhone: f.customer.phone,
      customerPreferredMethodOfContact: f.customer.preferredMethodOfContact,
      priceLow: f.priceLow,
      priceHigh: f.priceHigh,
      formattedPrice: formatter.format(f.priceHigh),
      singleGateCount: f.gates.singeGate,
      doubleGateCount: f.gates.doubleGate,
    });
  });

  return { estimates: to, totalPages, pageNumber, pageSize, total };
}

export function convertEstimateDetailsfrom(from: EstimateDetailsResponse): IEstimateDetails {
  const to: IEstimateDetails = {
    id: from.id,
    friendlyId: from.friendlyId,
    createdOn: new Date(from.createdOn).toLocaleString(),
    total: { min: from.total.min, max: from.total.max },
    worksite: {
      street: from.worksite.street,
      city: from.worksite.city,
      state: from.worksite.state,
      zipcode: from.worksite.zipcode,
      lat: from.worksite.lat,
      lng: from.worksite.lng,
    },
    customerContact: {
      name: from.customerContact.name,
      email: from.customerContact.email,
      telephone: from.customerContact.phone,
      preferredMethodOfContact: convertMethodOfContact(from.customerContact.preferredMethodOfContact),
    },
    segments: from.segments.map((s) => {
      return {
        type: s.type,
        style: s.style,
        color: s.color,
        height: s.height,
        calculatedLength: s.calculatedLength,
        doubleGateCount: s.doubleGateCount,
        singleGateCount: s.singleGateCount,
        totalRange: { min: s.priceRange.min, max: s.priceRange.max },
        coordsFences: s.coordsFence.map((cf) => {
          return {
            lat: cf.lat,
            lng: cf.lng,
          };
        }),
        coordsGates: s.coordsGate.map((gf) => {
          return {
            lat: gf.lat,
            lng: gf.lng,
            size: convertToGateSize(gf.size),
          };
        }),
      };
    }),
  };
  return to;
}

function convertMethodOfContact(method: string) {
  if (method === "Phone") {
    return "Phone preferred";
  }
  return "Email preferred";
}

function convertToGateSize(size: number) {
  if (size === 0) return "Single";
  return "Double";
}
