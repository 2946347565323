import { getDecodedToken, isTokenExpired } from "src/api/token";

export interface ILoggedInUser {
  name: string;
  id: string;
  companyId: string;
  // role: "Admin" | "User";
  // isOnboarded: boolean;
}

export function isUserAuthenticated(): boolean {
  // Checks if there is a saved token and it's still valid
  const token = getDecodedToken(); // GEtting token from localstorage
  if (token === undefined) return false;

  return !isTokenExpired(token); // handwaiving here
}

export function getUser(): ILoggedInUser | undefined {
  if (!isUserAuthenticated()) return undefined;
  const decoded = getDecodedToken();
  return {
    name: decoded!.name,
    id: decoded!.oid,
    companyId: decoded!.tid,
    // role: "User", //decoded!.role as "Admin" | "User",
    // isOnboarded: decoded!.isOnboarded === "True", // false
  };
}
