/* eslint-disable no-undef */
import * as React from "react";
import { GoogleMap, Marker, Polyline } from "@react-google-maps/api";

import doubleGateIcon from "src/icons/double_gate.svg";
import fenceNumberIcon from "src/icons/fence_id.svg";
import singleGateIcon from "src/icons/single_gate.svg";
import starIcon from "src/icons/star.svg";
import { ICoordsFence, ISegment } from "src/pages/Estimates/models";

interface IMapProps {
  worksiteLat: number;
  worksiteLng: number;
  segments: ISegment[];
  mapRef: React.MutableRefObject<google.maps.Map | undefined>;
}
/*
const toGoogleMapsPath = (coords: ICoordsFence[]) => {
  const path = new Array<google.maps.LatLng>();
  coords.forEach((c) => {
    path.push(new google.maps.LatLng(c.lat, c.lng));
  });

  return path;
};

const drawGates = (segments: ISegment[]) => {
  const gates: JSX.Element[] = [];

  segments.forEach((s, i) => {
    s.coordsGates.forEach((g, ii) => {
      const icon = { url: singleGateIcon, anchor: new google.maps.Point(9, 9) };
      if (g.size === "Double") {
        icon.url = doubleGateIcon;
      }
      gates.push(<Marker key={i + ii} icon={icon} position={new google.maps.LatLng(g.lat, g.lng)} />);
    });
  });

  return gates;
};

const drawSegmentId = (segments: ISegment[]) => {
  const ids: JSX.Element[] = [];
  segments.forEach((s, i) => {
    const coord = new google.maps.LatLng(s.coordsFences[0].lat, s.coordsFences[0].lng);
    const icon = { url: fenceNumberIcon, anchor: new google.maps.Point(9, 9) };
    const label = { text: "#" + (i + 1) };

    ids.push(<Marker key={i} position={coord} icon={icon} label={label} />);
  });
  return ids;
};

const MyMapComponent = withScriptjs<WithGoogleMapProps & IMapProps>(
  withGoogleMap((props) => (
    <GoogleMap defaultZoom={18} center={{ lat: props.worksiteLat, lng: props.worksiteLng }}>
      {props.segments.map((f, i) => {
        return <Polyline key={i} path={toGoogleMapsPath(f.coordsFences)} />;
      })}
      {drawGates(props.segments)}
      {drawSegmentId(props.segments)}
      <Marker key={"home"} position={new google.maps.LatLng(props.worksiteLat, props.worksiteLng)} icon={starIcon} />
    </GoogleMap>
  ))
);*/

const containerStyle = {
  width: "100%",
  height: "600px",
  //minHeight: "600px",
};

const GoogleMapWrapper = function (props: IMapProps) {
  const [map, setMap] = React.useState<google.maps.Map | null>(null);
  const center = { lat: props.worksiteLat, lng: props.worksiteLng };

  const handleOnLoad = React.useCallback(
    function callback(map: google.maps.Map) {
      // This is just an example of getting and using the map instance!!! don't just blindly copy!
      const bounds = new window.google.maps.LatLngBounds(center);
      map.setMapTypeId(google.maps.MapTypeId.SATELLITE);
      map.fitBounds(bounds);
      props.mapRef.current = map;
      setMap(map);
    },
    [center, props.mapRef]
  );

  const toGoogleMapsPath = (coords: ICoordsFence[]) => {
    const path = new Array<google.maps.LatLng>();
    coords.forEach((c) => {
      path.push(new google.maps.LatLng(c.lat, c.lng));
    });

    return path;
  };
  const drawGates = (segments: ISegment[]) => {
    const gates: JSX.Element[] = [];

    segments.forEach((s, i) => {
      s.coordsGates.forEach((g, ii) => {
        const icon = { url: singleGateIcon, anchor: new google.maps.Point(9, 9) };
        if (g.size === "Double") {
          icon.url = doubleGateIcon;
        }
        gates.push(<Marker key={i + ii} icon={icon} position={new google.maps.LatLng(g.lat, g.lng)} />);
      });
    });

    return gates;
  };

  const drawSegmentId = (segments: ISegment[]) => {
    const ids: JSX.Element[] = [];
    segments.forEach((s, i) => {
      const coord = new google.maps.LatLng(s.coordsFences[0].lat, s.coordsFences[0].lng);
      const icon = { url: fenceNumberIcon, anchor: new google.maps.Point(9, 9) };
      const label = { text: "#" + (i + 1) };

      ids.push(<Marker key={i} position={coord} icon={icon} label={label} />);
    });
    return ids;
  };
  const handleOnUnmount = React.useCallback(function callback(map: google.maps.Map) {
    setMap(null);
  }, []);

  const gestureHandling = "none";
  return (
    <GoogleMap
      mapContainerStyle={containerStyle}
      center={center}
      options={{
        fullscreenControl: false,
        gestureHandling,
        mapTypeControl: true,
        rotateControl: false,
        scaleControl: true,
        streetViewControl: false,
        tilt: 0,
        zoomControl: true,
      }}
      zoom={20}
      onLoad={handleOnLoad}
      onUnmount={handleOnUnmount}
    >
      {props.segments.map((f, i) => {
        return (
          <Polyline key={i} path={toGoogleMapsPath(f.coordsFences)} options={{ strokeColor: "red", strokeWeight: 4 }} />
        );
      })}
      {drawGates(props.segments)}
      {drawSegmentId(props.segments)}
      <Marker key={"home"} position={new google.maps.LatLng(props.worksiteLat, props.worksiteLng)} icon={starIcon} />
      {/* Child components, such as markers, info windows, etc. */}
    </GoogleMap>
  );
};
export default React.memo(GoogleMapWrapper);

//export default MyMapComponent;
