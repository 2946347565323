import { toast } from "react-toastify";
import { customAxios } from "src/api/api";
import { PortalSettings, UserProfile } from "./models";

interface ApiResponse<T> {
  data: T;
  hasError: boolean;
}

export const updateUserPassword = async (newPassword: string): Promise<ApiResponse<undefined>> => {
  let url = `api/v1/account/password`;
  try {
    const response = await customAxios.put(url, { newPassword });
    toast.success("User password updated.");
    return { data: response.data, hasError: false };
  } catch (err) {
    toast.error("Failed to update user password.");
    console.log(err);
  }
  return { data: undefined, hasError: true };
};

export const getUserProfileInformation = async (): Promise<ApiResponse<UserProfile | undefined>> => {
  let url = `api/v1/account/profile`;
  try {
    const response = await customAxios.get(url);
    return { data: response.data, hasError: false };
  } catch (err) {
    toast.error("Failed to load user profile");
    console.log(err);
  }
  return { data: undefined, hasError: true };
};

export const updateUserProfile = async (firstName: string, lastName: string): Promise<ApiResponse<undefined>> => {
  let url = `api/v1/account/profile`;
  try {
    const response = await customAxios.put(url, { firstName, lastName });

    return { data: response.data, hasError: false };
  } catch (err) {
    toast.error("Failed to upadate user profile");
    console.log(err);
  }
  return { data: undefined, hasError: true };
};

export const getPortalSettings = async (): Promise<ApiResponse<PortalSettings | undefined>> => {
  let url = `api/v1/company/portal/settings`;
  try {
    const response = await customAxios.get(url);
    return { data: response.data, hasError: false };
  } catch (err) {
    toast.error("Failed to load portal settings.");
    console.log(err);
  }
  return { data: undefined, hasError: true };
};
