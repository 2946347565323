export interface CompanyInformation {
  companyId: string;
  name: string;
  address1: string;
  address2: string;
  city: string;
  state: string;
  zipcode: string;
  email: string;
  phone: string;
  website: string;
}

export const EmptyCompanyInformation: CompanyInformation = {
  companyId: "",
  name: "",
  address1: "",
  address2: "",
  city: "",
  state: "",
  zipcode: "",
  email: "",
  phone: "",
  website: "",
};
