import React from "react";
import { Col, Container, Row } from "reactstrap";
import CreateFenceVariantForm from "./createFenceVariantForm";
import FenceVariantListTable from "./fenceVariantListTable";
import { useParams } from "react-router-dom";
import { FenceVariantDetails } from "./models";
import { UpdateStyleForm } from "./updateStyleForm";
import { useCreateNewVariant, useDeleteVariant, useGetFenceVariantList, useUpdateStyleDetails } from "./useHooks";
import { useGetFenceType } from "src/pages/Catalog/Types/useHooks";
import UploadImagesForm from "./uploadImagesForm";
import { CurrentImageView } from "./currentImageView";
import { toast } from "react-toastify";

function NoVariants(props: { display: boolean }) {
  if (!props.display) return <></>;

  return (
    <div
      className="border d-flex align-items-center justify-content-center"
      style={{ height: "200px", width: "100%", marginBottom: "20px" }}
    >
      <span>Please Add Your First Fence Variant</span>
    </div>
  );
}

export default function FenceVariantListPage() {
  let { typeid, id } = useParams();

  const { data, hasError, refreshItems } = useGetFenceVariantList(typeid!, id!);
  const { data: fenceType, hasError: hasErrorLoadingFenceTypes } = useGetFenceType(typeid!);
  const { createVariant, isCreateNewVariantLoading, hasError: hasErrorCreatingVariant } = useCreateNewVariant();
  const { deleteVariant, hasError: hasErrorDeleteVariant } = useDeleteVariant();
  const { updateStyle, isUpdateStyleLoading, hasError: hasErrorUpdatingStyleNameAndColors } = useUpdateStyleDetails();

  function handleCreateVariantFormSuccess(newVariant: FenceVariantDetails): void {
    createVariant(typeid!, id!, newVariant).then((r) => {
      if (!r.hasError && !r.hasValidationError) {
        toast.done("New fence style variant created.");
        refreshItems();
      } else if (r.hasValidationError) {
        r.data?.errors.forEach((e) => toast.error(e));
      }
    });
  }

  function handleDeleteVariantSuccess(event: React.MouseEvent<HTMLButtonElement, MouseEvent>): void {
    deleteVariant(typeid!, id!, event.currentTarget.id).then((r) => {
      if (r.data?.isSuccess) {
        toast.done("Fence variant deleted.");
        refreshItems();
      }
    });
  }

  function handleUpdateStyleNameAndColor(styleName: string, selectedColors: { label: string; value: any }[]) {
    updateStyle(
      typeid!,
      id!,
      styleName,
      selectedColors.map((c) => c.value)
    ).then((r) => {
      if (r.data?.isSuccess) {
        toast.success("Style updated.");
        refreshItems();
      }
    });
  }

  if (data === undefined) return <></>;
  return (
    <Container className="bg-light border" fluid>
      <Row>
        <Col>
          <div
            className="d-flex align-items-center justify-content-center"
            style={{ width: "100%", marginBottom: "50px" }}
          >
            <h3>{fenceType?.name} Fence</h3>
          </div>
        </Col>
      </Row>
      <Row>
        <Col md={4}>
          <UpdateStyleForm
            name={data.styleName}
            availableColors={fenceType?.availableColors || []}
            selectedColors={data.colors}
            onFormSubmitSuccess={handleUpdateStyleNameAndColor}
            isLoading={isUpdateStyleLoading}
          />
          <Row>
            <Col>
              {data.imageUrl && (
                <CurrentImageView
                  typeId={typeid!}
                  styleId={id!}
                  uuid={data.uuid!}
                  cdnUrl={data.imageUrl!}
                  refresh={refreshItems}
                />
              )}

              <UploadImagesForm
                typeId={typeid!}
                styleId={id!}
                imageUrl={data.imageUrl}
                uuid={data.uuid}
                refresh={refreshItems}
              />
            </Col>
          </Row>
        </Col>
        <Col md={8}>
          <Row>
            <Col>
              <FenceVariantListTable variants={data.variants} onDelete={handleDeleteVariantSuccess} />
              <NoVariants display={data.variants.length === 0} />
            </Col>
          </Row>
          <Row>
            <Col>
              <CreateFenceVariantForm
                onFormSubmitSuccess={handleCreateVariantFormSuccess}
                configuredHeights={data.variants.map((x) => x.height)}
                isLoading={isCreateNewVariantLoading}
              />
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  );
}
