import { FileUploaderRegular, type UploadCtxProvider } from "@uploadcare/react-uploader";
import { OutputFileEntry } from "@uploadcare/blocks";
import "@uploadcare/react-uploader/core.css";
import { useUpdateStyleImage } from "./useHooks";

import { useRef, useState } from "react";

type UploadImagesFormProps = {
  typeId: string;
  styleId: string;
  uuid?: string;
  imageUrl?: string;
  refresh: () => void;
};

const localeDefinitionOverride = {
  en: {
    "upload-file": "Upload Fence Style Image",
    "choose-file": "Upload Fence Style Image",
  },
};

export default function UploadImagesForm(props: UploadImagesFormProps) {
  const [uploadedFiles, setUploadedFiles] = useState<OutputFileEntry<"success">[]>([]);
  const ctxProviderRef = useRef<InstanceType<UploadCtxProvider>>(null);
  const { updateStyleImage, hasError } = useUpdateStyleImage();

  const handleChangeEvent = (files: any) => {
    setUploadedFiles([...files.allEntries.filter((f: any) => f.status === "success")] as OutputFileEntry<"success">[]);
  };

  const resetUploaderState = () => ctxProviderRef.current?.uploadCollection.clearAll();

  const handleModalCloseEvent = () => {
    const firstFile = uploadedFiles[0];
    if (firstFile === undefined) return;

    updateStyleImage(props.typeId, props.styleId, { uuid: firstFile.uuid, cdnUrl: firstFile.cdnUrl })
      .then((r) => {
        if (r.hasError) {
          //error
        } else {
          resetUploaderState();
          setUploadedFiles([]);
        }
      })
      .then(() => {
        props.refresh();
      });
  };
  return (
    <div
      className="d-flex align-items-center justify-content-center"
      style={{ height: "200px", width: "100%", marginTop: "20px" }}
    >
      <FileUploaderRegular
        pubkey="cdc49aabac1dcb539bd7"
        maxLocalFileSizeBytes={10000000}
        localeDefinitionOverride={localeDefinitionOverride}
        apiRef={ctxProviderRef}
        onChange={handleChangeEvent}
        onModalClose={handleModalCloseEvent}
        multiple={false}
        imgOnly={true}
        sourceList="local, url, camera"
      />
    </div>
  );
}
