import React, { useState } from "react";
import Select, { MultiValue, ActionMeta } from "react-select";
import { Form, Row, Col, FormGroup, Label, Input, Button, InputGroup, InputGroupText } from "reactstrap";
import validationCssClassFormHelper from "src/utils";
import { FenceVariantDetails } from "./models";
import { SpinnerButton } from "src/components/SpinnerButton";

type FormState = {
  values: {
    [key: string]: {
      value: string;
      isValid?: boolean;
    };
  };
};

type VariantFormProps = {
  onFormSubmitSuccess: (variant: FenceVariantDetails) => void;
  configuredHeights: number[];
  isLoading: boolean;
};

const initialState = {
  values: {
    priceLabor: {
      value: "",
    },
    priceMaterial: {
      value: "",
    },
    priceGateSingle: {
      value: "",
    },
    priceGateDouble: {
      value: "",
    },
    height: {
      value: "",
    },
  },
};
export default function CreateFenceVariantForm(props: VariantFormProps) {
  const [formValues, updateFormValues] = useState<FormState>(initialState);

  // const options = props.availableHeights.map((h, i) => {
  //   return { label: `${h}ft`, value: h };
  // });

  // const disableFormElements = options.length === 0;
  const disableFormElements = false;
  function onFormValueChange(event: React.ChangeEvent<HTMLInputElement>): void {
    const reg = new RegExp("^[1-9]\\d*$");
    if (event.currentTarget.value === "") {
      formValues.values[event.currentTarget.name] = {
        value: event.currentTarget.value,
        isValid: undefined,
      };
      updateFormValues({ ...formValues });
    } else {
      if (event.currentTarget.value === "0") {
        formValues.values[event.currentTarget.name] = {
          value: event.currentTarget.value,
          isValid: false,
        };
      }
      formValues.values[event.currentTarget.name] = {
        value: event.currentTarget.value,
        isValid: reg.test(event.currentTarget.value),
      };
      updateFormValues({ ...formValues });
    }

    if (event.currentTarget.name == "height") {
      if (props.configuredHeights.some((x) => x === +event.currentTarget.value)) {
        formValues.values["height"].isValid = false;
      }
      updateFormValues({ ...formValues });
    }
  }

  function handleSubmit(event: React.FormEvent<HTMLFormElement>): void {
    event.preventDefault();
    event.stopPropagation();

    for (const [key, value] of Object.entries(formValues.values)) {
      if (!value.isValid) {
        return;
      }
    }

    props.onFormSubmitSuccess({
      id: Math.random().toString(),
      height: +formValues.values["height"].value,
      priceLabor: +formValues.values["priceLabor"].value,
      priceMaterial: +formValues.values["priceMaterial"].value,
      priceSingleGate: +formValues.values["priceGateSingle"].value,
      priceDoubleGate: +formValues.values["priceGateDouble"].value,
    });

    updateFormValues({
      values: {
        priceLabor: {
          value: "",
        },
        priceMaterial: {
          value: "",
        },
        priceGateSingle: {
          value: "",
        },
        priceGateDouble: {
          value: "",
        },
        height: {
          value: "",
        },
      },
    });
  }
  /*
  let customStyles = undefined;
  if (formValues.is === false) {
    customStyles = {
      control: (provided: any) => ({
        ...provided,
        borderColor: "red",
      }),
    };
  }*/

  return (
    <Form onSubmit={handleSubmit}>
      <Row>
        <Col md={3}>
          <FormGroup>
            <Label for="height">Height (FT)</Label>
            <InputGroup>
              <Input
                name="height"
                className={validationCssClassFormHelper("", formValues.values["height"].isValid)}
                value={formValues.values["height"].value}
                onChange={onFormValueChange}
                disabled={disableFormElements}
                required
              />
            </InputGroup>
          </FormGroup>
        </Col>
        <Col md={2}>
          <FormGroup>
            <Label for="exampleAddress">Labor (LFT)</Label>
            <InputGroup>
              <InputGroupText>$</InputGroupText>
              <Input
                name="priceLabor"
                className={validationCssClassFormHelper("", formValues.values["priceLabor"].isValid)}
                value={formValues.values["priceLabor"].value}
                onChange={onFormValueChange}
                disabled={disableFormElements}
                required
              />
            </InputGroup>
          </FormGroup>
        </Col>
        <Col md={2}>
          <FormGroup>
            <Label for="exampleAddress2">Material (LFT)</Label>
            <InputGroup>
              <InputGroupText>$</InputGroupText>
              <Input
                name="priceMaterial"
                className={validationCssClassFormHelper("", formValues.values["priceMaterial"].isValid)}
                value={formValues.values["priceMaterial"].value}
                onChange={onFormValueChange}
                disabled={disableFormElements}
                required
              />
            </InputGroup>
          </FormGroup>
        </Col>
        <Col md={2}>
          <FormGroup>
            <Label for="exampleCity">Gate Single Width</Label>
            <InputGroup>
              <InputGroupText>$</InputGroupText>
              <Input
                name="priceGateSingle"
                className={validationCssClassFormHelper("", formValues.values["priceGateSingle"].isValid)}
                value={formValues.values["priceGateSingle"].value}
                disabled={disableFormElements}
                onChange={onFormValueChange}
              />
            </InputGroup>
          </FormGroup>
        </Col>
        <Col md={2}>
          <FormGroup>
            <Label for="exampleState">Gate Double Width</Label>
            <InputGroup>
              <InputGroupText>$</InputGroupText>
              <Input
                name="priceGateDouble"
                className={validationCssClassFormHelper("", formValues.values["priceGateDouble"].isValid)}
                value={formValues.values["priceGateDouble"].value}
                onChange={onFormValueChange}
                disabled={disableFormElements}
              />
            </InputGroup>
          </FormGroup>
        </Col>
      </Row>
      <SpinnerButton
        color="primary"
        type="submit"
        disabled={disableFormElements}
        style={{ marginBottom: "10px" }}
        text="Add"
        isloading={props.isLoading}
      />
    </Form>
  );
}
