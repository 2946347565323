import { useState } from "react";
import { Button, Form, Input, Label } from "reactstrap";
import { useSaveRegistration } from "./useHooks";
import { Registration } from "./models";
import { error } from "console";
import { useNavigate } from "react-router";
import { setToken } from "src/api/token";

type FormValues = {
  [key: string]: string;
};
export function RegisterPage() {
  const navigate = useNavigate();

  const [formData, setFormData] = useState<FormValues>({
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    companyName: "",
    accessCode: "",
  });

  const { saveRegistration, registrationResult, hasError, validationErrors, isSaving } = useSaveRegistration();

  function handleFormSubmit(event: React.FormEvent<HTMLFormElement>): void {
    event.preventDefault();
    event.stopPropagation();

    var registration: Registration = {
      firstName: formData.firstName,
      lastName: formData.lastName,
      email: formData.email,
      password: formData.password,
      companyName: formData.companyName,
      accessCode: formData.accessCode,
    };

    saveRegistration(registration).then((r) => {
      if (r.data?.isSuccess) {
        setTimeout(() => navigate("/"), 500); //FIXME: hacky
      }
    });
  }

  function handleUserInput(event: React.ChangeEvent<HTMLInputElement>): void {
    formData[event.currentTarget.name] = event.currentTarget.value;
    setFormData({ ...formData });
  }

  /* function isPasswordComplex(password: string) {
    if (password.length < 8) {
      validationErrors.push("Password must be greater than 8 characters.");
      setValidationErrors(validationErrors);
      return;
    }

    var hasUpperCase = /[A-Z]/.test(password);
    var hasLowerCase = /[a-z]/.test(password);
    var hasNumbers = /\d/.test(password);
    var hasNonalphas = /\W/.test(password);
    const arr = [hasUpperCase, hasLowerCase, hasNumbers, hasNonalphas];
    const count = arr.filter(Boolean).length;
    if (count < 3) {
      return;
    }
  }*/
  return (
    <>
      <div className="container col-xl-10 col-xxl-8 px-4 py-5">
        <div className="row align-items-center g-lg-5 py-5">
          <div className="col-lg-4 text-center text-lg-start">
            <h1 className="display-4 fw-bold lh-1 mb-3">Register New Account</h1>
            <p className="col-lg-5 fs-4"></p>
          </div>
          <div className="col-md-10 mx-auto col-lg-7">
            <ValidationErrors errors={validationErrors} />
            <Form
              className="p-4 p-md-5 border rounded-3 bg-light"
              data-bitwarden-watching="1"
              onSubmit={handleFormSubmit}
            >
              <div className="row">
                <div className="col">
                  <div className="form-floating mb-3">
                    <Input
                      type="text"
                      name="firstName"
                      className="form-control"
                      placeholder="name@example.com"
                      required={true}
                      onChange={handleUserInput}
                    />
                    <Label for="floatingFIrstName">First Name</Label>
                  </div>
                </div>
                <div className="col">
                  <div className="form-floating mb-3">
                    <Input
                      type="text"
                      name="lastName"
                      className="form-control"
                      placeholder="name@example.com"
                      required={true}
                      onChange={handleUserInput}
                    />
                    <Label for="floatingLastName">Last Name</Label>
                  </div>
                </div>
              </div>
              <div className="form-floating mb-3">
                <Input
                  type="text"
                  name="companyName"
                  className="form-control"
                  placeholder="Company Name"
                  required={true}
                  onChange={handleUserInput}
                />
                <Label for="floatingCompany">Company Name</Label>
              </div>
              <div className="form-floating mb-3">
                <Input
                  type="email"
                  name="email"
                  className="form-control"
                  placeholder="name@example.com"
                  required={true}
                  onChange={handleUserInput}
                />
                <Label for="floatingInput">Email address</Label>
              </div>
              <div className="form-floating mb-3">
                <Input
                  type="password"
                  name="password"
                  className="form-control"
                  placeholder="Password"
                  required={true}
                  onChange={handleUserInput}
                />
                <Label for="floatingPassword">Password</Label>
              </div>
              <div className="form-floating mb-3">
                <Input
                  type="text"
                  name="accessCode"
                  className="form-control"
                  placeholder="Access Code"
                  required={true}
                  onChange={handleUserInput}
                />
                <Label for="floatingAccessCode">Invite Code</Label>
              </div>
              <Button className="w-100" size="lg" color="primary" type="submit">
                Signup
              </Button>
            </Form>
          </div>
        </div>
      </div>
    </>
  );
}

function ValidationErrors(props: { errors: Array<string> }) {
  if (props.errors.length === 0) return <></>;
  return (
    <div
      className="border d-flex align-items-center justify-content-left"
      style={{ width: "100%", marginBottom: "20px", paddingTop: "10px", background: "#ffbab3" }}
    >
      <ul>
        {props.errors.map((msg, i) => {
          return <li key={i}>{msg}</li>;
        })}
      </ul>
    </div>
  );
}
