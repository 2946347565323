import { useState } from "react";
import { Registration, RegistrationResult } from "./models";
import { saveUserRegistration } from "./service";

export function useSaveRegistration() {
  const [isSaving, setSaving] = useState(false);
  const [hasError, setError] = useState(false);
  const [registrationResult, setResult] = useState<RegistrationResult>();
  const [validationErrors, setValidationErrors] = useState<Array<string>>([]);

  const saveRegistration = (registration: Registration) => {
    setSaving(true); // Adding loading state
    return saveUserRegistration(registration).then((r) => {
      setSaving(false); // Removing loading state
      setResult(r.data);
      setError(r.hasError);
      setValidationErrors(r.data?.errors || []);
      return r;
    });
  };
  return { saveRegistration, registrationResult, hasError, validationErrors, isSaving };
}
