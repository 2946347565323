import { getStyleVariantsResponse, createFenceVariantRequest } from "./apiContracts";
import { FenceVariantDetails, FenceVariants } from "./models";

export function convertFromGetStyleVariantsResponse(
  from: getStyleVariantsResponse | undefined
): FenceVariants | undefined {
  if (from === undefined) return undefined;

  const to: FenceVariants = {
    id: from.id,
    styleName: from.name,
    colors: from.colors.map((x) => {
      return { label: x.name, value: x.id };
    }),
    variants: from.variants.map((x) => {
      return {
        id: x.id,
        height: x.height,
        priceLabor: x.priceLabor,
        priceMaterial: x.priceMaterial,
        priceSingleGate: x.priceSingleGate,
        priceDoubleGate: x.priceDoubleGate,
      };
    }),
    imageUrl: from.styleImage?.cdnUrl,
    uuid: from.styleImage?.uuid,
  };
  return to;
}

export function convertFromVariantDetails(from: FenceVariantDetails): createFenceVariantRequest {
  return {
    priceMaterial: from.priceMaterial,
    priceLabor: from.priceLabor,
    priceSingleGate: from.priceSingleGate,
    priceDoubleGate: from.priceDoubleGate,
    height: from.height,
  };
}
