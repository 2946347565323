import { Row, Col, Card, CardBody, CardHeader } from "reactstrap";
import { CompanyInformationForm } from "./companyInformationForm";
import { useGetCompanyInformation } from "./useHooks";

export function ProfilePage() {
  return (
    <>
      <Row className="justify-content-center">
        <Col md={6}>
          <Card className="my-2">
            <CardHeader>Company Profile</CardHeader>
            <CardBody>
              <CompanyInformationForm />
            </CardBody>
          </Card>
        </Col>
      </Row>
    </>
  );
}
