import * as React from "react";
import { useNavigate } from "react-router-dom";
import { Table, Button } from "reactstrap";

type TableRowProps = {
  typeId: string;
  typeName: string;
  styleId: string;
  styleName: string;
  variantCount: number;
  onDelete: (styleId: string) => void;
};

function TableRow(props: TableRowProps) {
  const navigate = useNavigate();

  const handleEdit = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    const id = event.currentTarget.id;
    navigate(`${props.typeName.toLocaleLowerCase()}/${props.typeId}/style/${id}`);
  };
  const handleDelete = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    props.onDelete(props.styleId);
  };

  return (
    <tr>
      <td>{props.styleName}</td>
      <td>{props.variantCount}</td>
      <td>
        <Button id={props.styleId} color="primary" outline size="sm" onClick={handleEdit}>
          Edit
        </Button>{" "}
        <Button color="danger" outline size="sm" onClick={handleDelete}>
          Delete
        </Button>
      </td>
    </tr>
  );
}

type StyleTableProps = {
  styles: { id: string; name: string; variantCount: number }[];
  typeId: string;
  typeName: string;
  onDelete: (styleId: string) => void;
};

export default function StyleTable(props: StyleTableProps) {
  return (
    <Table responsive>
      <thead>
        <tr>
          <th>Style Name</th>
          <th>Configured Variants</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        {props.styles.map((r, i) => (
          <TableRow
            key={r.id}
            styleId={r.id}
            styleName={r.name}
            variantCount={r.variantCount}
            typeId={props.typeId}
            typeName={props.typeName}
            onDelete={props.onDelete}
          />
        ))}
      </tbody>
    </Table>
  );
}
