import { useState } from "react";
import { Col, Form, FormGroup, Input, Label, Row } from "reactstrap";
import { useFenceTypeDisabled } from "../useHooks";

export default function FenceTypeDisabledForm(props: {
  fenceTypeName: string;
  isDisabled: boolean;
  fenceTypeId: string;
}) {
  const [isDisabled, setDisabled] = useState(props.isDisabled);
  const { setDisabledStatus, hasError } = useFenceTypeDisabled();

  const handleFenceTypeTogle = (e: React.ChangeEvent<HTMLInputElement>) => {
    const currentState = e.currentTarget.checked;
    setDisabledStatus(props.fenceTypeId, currentState).then((r) => {
      if (hasError) {
      } else {
        setDisabled(currentState);
      }
    });
  };

  return (
    <Form>
      <Row>
        <Col>{props.fenceTypeName}</Col>
        <Col>
          <FormGroup switch className="float-end">
            <Input type="switch" role="switch" checked={isDisabled} onChange={handleFenceTypeTogle} />
            <Label check>Disabled</Label>
          </FormGroup>
        </Col>
      </Row>
    </Form>
  );
}
