import { useEffect, useState } from "react";
import { OnboardProgress } from "./models";
import { getOnboardingProgress } from "./service";

export function useGetOnboardingStatus() {
  const [onboardingStatus, setOnboardingStatus] = useState<OnboardProgress>({
    isCatalogComplete: false,
    isCompanyProfileComplete: false,
    isFullyOnboarded: false,
  });
  const [hasError, setError] = useState(false);

  useEffect(() => {
    getOnboardingProgress().then((r) => {
      setError(r.hasError);
      setOnboardingStatus(r.data);
    });
  }, []);

  return { onboardingStatus, hasError };
}
