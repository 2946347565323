import { toast } from "react-toastify";
import { customAxios } from "src/api/api";
import { CompanyInformation } from "./models";

interface ApiResponse<T> {
  data: T;
  hasError: boolean;
}

export const getCompanyInformation = async (): Promise<ApiResponse<CompanyInformation | undefined>> => {
  let url = `/api/v1/company/`;
  try {
    const response = await customAxios.get(url);
    return { data: response.data, hasError: false };
  } catch (err) {
    toast.error("Failed to load company profile");
    console.log(err);
  }
  return { data: undefined, hasError: true };
};

export const saveCompanyInformation = async (profile: CompanyInformation): Promise<ApiResponse<undefined>> => {
  let url = `/api/v1/company`;
  try {
    const response = await customAxios.put(url, profile);
    return { data: response.data, hasError: false };
  } catch (err) {
    toast.error("Failed to save company profile");
    console.log(err);
  }
  return { data: undefined, hasError: true };
};
