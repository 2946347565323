import { customAxios } from "src/api/api";
import { OnboardProgress } from "./models";
import { toast } from "react-toastify";

interface ApiResponse<T> {
  data: T;
  hasError: boolean;
}

export const getOnboardingProgress = async (): Promise<ApiResponse<OnboardProgress>> => {
  let url = `/api/v1/account/status?onlyOnboarding=true`;
  try {
    const isComplete = sessionStorage.getItem("onboarding_complete");
    if (isComplete !== null) {
      return {
        data: {
          isCatalogComplete: true,
          isFullyOnboarded: true,
          isCompanyProfileComplete: true,
        },
        hasError: false,
      };
    }

    const response = await customAxios.get<OnboardProgress>(url);

    if (response.data.isFullyOnboarded) {
      sessionStorage.setItem("onboarding_complete", "true");
    }

    return { data: response.data, hasError: false };
  } catch (err) {
    toast.error("Failed to load onboarding status");
    console.log(err);
  }
  return {
    data: {
      isCatalogComplete: false,
      isCompanyProfileComplete: false,
      isFullyOnboarded: false,
    },
    hasError: true,
  };
};
