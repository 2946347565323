import { useNavigate, useParams } from "react-router";
import {
  Row,
  Col,
  Card,
  CardBody,
  UncontrolledDropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";
import FenceSegmentSummary from "./components/fenceSegmentSummary";
import { useRef, useState } from "react";
import { IEstimateDetails } from "./models";
import { formatCurrency } from "src/utils";
import { useDispositionEstimate, useGetEstimateDetails } from "./useHooks";
import MyMapComponent from "./components/myGoogleMaps";
import { SpinnerButton } from "src/components/SpinnerButton";
import { toast } from "react-toastify";

export function EstimateDetailPage() {
  const { id } = useParams();
  const mapRef = useRef<google.maps.Map>();
  const { data: estimate } = useGetEstimateDetails(id!);

  const [modal, setModal] = useState(false);
  const [dispositionCode, setDispositionCode] = useState("");

  const toggle = () => setModal(!modal);

  function handleDispositionSelection(event: React.MouseEvent<HTMLButtonElement, MouseEvent>): void {
    setModal(true); //open modal
    setDispositionCode(event.currentTarget.value);
  }

  return (
    <>
      <ConfirmCloseModal isOpen={modal} toggle={toggle} estimateId={id!} dispositionCode={dispositionCode} />
      <Row>
        <Col md={12}>
          <Card>
            <CardBody>
              <Row>
                <Col md={12} className="float-end">
                  <div className="float-end">
                    <UncontrolledDropdown>
                      <DropdownToggle caret color="danger">
                        Mark As Closed
                      </DropdownToggle>
                      <DropdownMenu dark>
                        <DropdownItem value="0" onClick={handleDispositionSelection}>
                          Bad Contact Information
                        </DropdownItem>
                        <DropdownItem value="1" onClick={handleDispositionSelection}>
                          Can't Reach Customer
                        </DropdownItem>
                        <DropdownItem value="2" onClick={handleDispositionSelection}>
                          Other
                        </DropdownItem>
                        <DropdownItem divider />
                        <DropdownItem value="3" onClick={handleDispositionSelection}>
                          Onsite Visit Scheduled
                        </DropdownItem>
                      </DropdownMenu>
                    </UncontrolledDropdown>
                  </div>
                </Col>
              </Row>

              <Row>
                <Col md={6}>
                  <div className="info">
                    <h4 className="info-title">Estimate ID: #{estimate.friendlyId}</h4>
                    <p>Date: {estimate.createdOn}</p>
                  </div>
                </Col>
                <Col md={6}>
                  <div className="info">
                    <h4 className="info-title">
                      Total: {formatCurrency(estimate.total.min)} - {formatCurrency(estimate.total.max)}
                    </h4>
                  </div>
                </Col>
              </Row>
              <hr />
              <Row>
                <Col md={6}>
                  <h5>Work Site</h5>
                  <address>
                    {estimate.worksite.street}
                    <br />
                    {estimate.worksite.city}, {estimate.worksite.state}, {estimate.worksite.zipcode}
                  </address>
                </Col>
                <Col md={6}>
                  <CustomerContact
                    phone={estimate.customerContact.telephone}
                    name={estimate.customerContact.name}
                    email={estimate.customerContact.email}
                    contactMethod={estimate.customerContact.preferredMethodOfContact}
                  />
                </Col>
              </Row>
              <Row>
                <Col md={8}>
                  <h4>Summary</h4>
                </Col>
              </Row>
              <Row>
                {estimate.segments.map((s, i) => (
                  <FenceSegmentSummary key={i} segmentNumber={i + 1} segmentDetails={s} />
                ))}
              </Row>
              <Row>
                <Col md={12}>
                  <h4>Outline</h4>
                </Col>
                <MyMapComponent
                  worksiteLat={estimate.worksite.lat}
                  worksiteLng={estimate.worksite.lng}
                  segments={estimate.segments}
                  mapRef={mapRef}
                />
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </>
  );
}

const CustomerContact = function (props: { name: string; phone: string; email: string; contactMethod: string }) {
  const hasName = props.name !== "" ? true : false;
  const hasPhone = props.phone !== "" ? true : false;
  const hasEmail = props.email !== "" ? true : false;
  const hasContactMethod = props.contactMethod !== "" ? true : false;

  return (
    <>
      <h5>Contact Information</h5>
      <p>
        {hasName && (
          <span>
            {props.name}
            <br />
          </span>
        )}
        {hasPhone && (
          <span>
            {props.phone}
            <br />
          </span>
        )}
        {hasEmail && (
          <span>
            {props.email}
            <br />
          </span>
        )}
        {hasContactMethod && `Preferred Contact Method: ${props.contactMethod}`}
      </p>
    </>
  );
};

type ConfirmCloseModelProps = {
  isOpen: boolean;
  toggle: () => void;
  estimateId: string;
  dispositionCode: string;
};
function ConfirmCloseModal(props: ConfirmCloseModelProps) {
  const { dispositionEstimate, isSaving } = useDispositionEstimate();
  const navigate = useNavigate();
  const handleDisposition = () => {
    dispositionEstimate(props.estimateId!, props.dispositionCode).then((r) => {
      if (!r.hasError) {
        navigate(`/estimates`);
        toast.success("Estimate closed");
      }
    });
  };
  return (
    <>
      <Modal isOpen={props.isOpen} toggle={props.toggle}>
        <ModalHeader toggle={props.toggle}>Confirm Closing of Estimate</ModalHeader>
        <ModalBody>
          This estimate will not be displayed to you again. Only mark as closed if you are done with it and no longer
          want to see it.
        </ModalBody>
        <ModalFooter>
          <SpinnerButton
            color="primary"
            text="Mark as Closed"
            isloading={isSaving}
            onClick={handleDisposition}
          ></SpinnerButton>{" "}
          <Button color="secondary" onClick={props.toggle}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
}
