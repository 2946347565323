import * as React from "react";
import { Table, Button } from "reactstrap";
import { FenceVariantDetails } from "./models";

const formatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",

  // These options are needed to round to whole numbers if that's what you want.
  //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
  maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
});

type VariantsTableRowProps = {
  id: string;
  heights: string[];
  priceLabor: string;
  priceMatial: string;
  priceGateSinge: string;
  priceGateDouble: string;
  onDelete: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
};
function VariantsTableRow(props: VariantsTableRowProps) {
  console.log(props);
  return (
    <tr key={props.id}>
      <td>{props.heights.join(", ")}</td>
      <td>{formatter.format(+props.priceLabor)}</td>
      <td>{formatter.format(+props.priceMatial)}</td>
      <td>{formatter.format(+props.priceGateSinge)}</td>
      <td>{formatter.format(+props.priceGateDouble)}</td>
      <td>
        <Button id={props.id} color="danger" outline size="sm" onClick={props.onDelete}>
          Delete
        </Button>
      </td>
    </tr>
  );
}

type VariantsTableProps = {
  variants: FenceVariantDetails[];
  onDelete: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
};

export default function FenceVariantListTable(props: VariantsTableProps) {
  return (
    <Table responsive>
      <thead>
        <tr>
          <th>Heights (ft)</th>
          <th>Price Labor (LFT)</th>
          <th>Price Materials (LFT)</th>
          <th>Gate Price (single)</th>
          <th>Gate Price (double)</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        {props.variants.map((v, i) => {
          return (
            <VariantsTableRow
              key={i}
              id={v.id}
              heights={[v.height.toString()]}
              priceLabor={v.priceLabor.toString()}
              priceMatial={v.priceMaterial.toString()}
              priceGateSinge={v.priceSingleGate.toString()}
              priceGateDouble={v.priceDoubleGate.toString()}
              onDelete={props.onDelete}
            />
          );
        })}
      </tbody>
    </Table>
  );
}
