import { useEffect, useState } from "react";
import { getEstimateList, getEstimateDetails, dispositionEstimate as dispositionEstimateApiCall } from "./service";
import { EstimateSummary, IEstimateDetails } from "./models";

export function useGetEstimateList() {
  const [isLoading, setLoading] = useState(true);
  const [data, setItems] = useState<EstimateSummary[]>();
  const [currentPage, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [total, setTotal] = useState(0);
  const [pageSize, setPageSize] = useState(0);

  const [qs, setQS] = useState("");
  const [refresh, setRefresh] = useState(false);
  const [hasError, setError] = useState(false);

  useEffect(() => {
    getEstimateList(qs).then((r) => {
      setItems(r.data.estimates);
      setTotalPages(r.data.totalPages);
      setPage(r.data.currentPage);
      setTotal(r.data.total);
      setPageSize(r.data.pageSize);
      setError(r.hasError);
      setLoading(false);
    });
  }, [refresh, qs]);

  const refreshEstimates = (params?: { [key in string]: string }) => {
    let queryString = "";
    if (params) {
      Object.keys(params).forEach((k) => {
        queryString = queryString + k + "=" + params[k];
      });
    }
    setQS(queryString);
    setRefresh(!refresh);
  };

  return { data, currentPage, totalPages, pageSize, total, hasError, isLoading, refreshEstimates };
}

export function useGetEstimateDetails(estimateId: string) {
  const [isLoading, setLoading] = useState(true);
  const [data, setItems] = useState<IEstimateDetails>({
    id: "",
    friendlyId: "",
    createdOn: "",
    total: { min: 0, max: 0 },
    worksite: { street: "", city: "", state: "", zipcode: "", lng: 0, lat: 0 },
    customerContact: { name: "", email: "", telephone: "", preferredMethodOfContact: "" },
    segments: [],
  });

  const [hasError, setError] = useState(false);

  useEffect(() => {
    getEstimateDetails(estimateId).then((r) => {
      if (!r.hasError) {
        setItems(r.data!);
      }
      setError(r.hasError);
    });
  }, [estimateId]);

  return { data, hasError };
}

export const useDispositionEstimate = () => {
  const [errorSavingEstimate, setError] = useState(false);
  const [isSaving, setSaving] = useState(false);

  const dispositionEstimate = (id: string, reason: string) => {
    setSaving(true); // Adding loading state
    return dispositionEstimateApiCall(id, reason).then((r) => {
      setSaving(false); // Removing loading state
      setError(r.hasError);
      return r;
    });
  };

  return { dispositionEstimate, errorSavingEstimate, isSaving };
};
