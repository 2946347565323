import * as React from "react";
import { Row, Col } from "reactstrap";
import TypeCard from "./card/fenceTypeDetailsCard";
import { useGetFenceTypesList } from "./useHooks";

export default function FenceTypesListPage() {
  const { data, hasError, refreshItems } = useGetFenceTypesList();

  return (
    <>
      <Row>
        {data.map((x, i) => (
          <Col key={i} xl={6} lg={12} md={12} sm={12}>
            <TypeCard key={x.name} fenceType={x} refresh={refreshItems} />
          </Col>
        ))}
      </Row>
    </>
  );
}
