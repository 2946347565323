import axios from "axios";
import { getToken } from "./token";

/*const token = getToken();

const instance = axios.create({
  baseURL: "http://localhost:5033/", // "https://fencebuildr-api.azurewebsites.net/", //process.env.REACT_APP_FENCE_API_SERVICE_URL,
  timeout: 25000,
});

instance.interceptors.request.use(
  (config) => {
    if (token !== undefined) {
      config.headers.Authorization = "Bearer " + token;
    }
    config.headers.genericKey = "someGenericValue";
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);
const responseBody = (response: AxiosResponse) => response.data;

const requests = {
  get: (url: string) => instance.get(url).then(responseBody),
  post: (url: string, body: {}) => instance.post(url, body).then(responseBody),
  put: (url: string, body: {}) => instance.put(url, body).then(responseBody),
  delete: (url: string) => instance.delete(url).then(responseBody),
};
export { requests };
*/
//new

const token = getToken();

const customAxios = axios.create({
  baseURL: process.env.REACT_APP_FENCE_API_SERVICE_URL, //"http://localhost:5033/", //https://fencebuildr-api.azurewebsites.net/",
  timeout: 5000,
});

customAxios.defaults.validateStatus = (status) => status >= 200 && status <= 401;

customAxios.interceptors.request.use(
  (config) => {
    if (token !== undefined) {
      config.headers.Authorization = "Bearer " + token;
    }
    //config.headers.genericKey = "someGenericValue";
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export { customAxios };
