import { Row, Col, FormGroup, Label, Input, Form } from "reactstrap";
import { SpinnerButton } from "src/components/SpinnerButton";
import { useState } from "react";
import { CompanyInformation, EmptyCompanyInformation } from "./models";
import { useGetCompanyInformation, useSubmitCompanyProfile } from "./useHooks";
import { toast } from "react-toastify";

export function CompanyInformationForm() {
  const { data: companyProfile, setItems } = useGetCompanyInformation();

  const { submitCompanyProfile, hasError, isSaving } = useSubmitCompanyProfile();

  function handleFormSubmit(event: React.FormEvent<HTMLFormElement>): void {
    event.preventDefault();
    submitCompanyProfile(companyProfile).then((r) => {
      if (!r.hasError) {
        toast.success("Company profile information updated");
      }
    });
  }

  function handleChange(event: React.ChangeEvent<HTMLInputElement>): void {
    switch (event.currentTarget.name) {
      case "companyName":
        companyProfile.name = event.currentTarget.value;
        break;
      case "address":
        companyProfile.address1 = event.currentTarget.value;
        break;
      case "address2":
        companyProfile.address2 = event.currentTarget.value;
        break;
      case "city":
        companyProfile.city = event.currentTarget.value;
        break;
      case "state":
        companyProfile.state = event.currentTarget.value;
        break;
      case "zip":
        companyProfile.zipcode = event.currentTarget.value;
        break;
      case "email":
        companyProfile.email = event.currentTarget.value;
        break;
      case "telephone":
        companyProfile.phone = event.currentTarget.value;
        break;
      case "website":
        companyProfile.website = event.currentTarget.value;
        break;
      default:
        break;
    }
    setItems({ ...companyProfile });
  }

  return (
    <Form onSubmit={handleFormSubmit}>
      <FormGroup>
        <Label for="companyName">Name</Label>
        <Input id="companyName" name="companyName" value={companyProfile.name} required onChange={handleChange} />
      </FormGroup>
      <FormGroup>
        <Label for="address">Address</Label>
        <Input id="address" name="address" value={companyProfile.address1 ?? ""} required onChange={handleChange} />
      </FormGroup>
      {/*<FormGroup>
        <Label for="exampleAddress2">Address 2</Label>
        <Input id="exampleAddress2" name="address2" defaultValue={companyProfile.address2} onChange={handleChange} />
      </FormGroup>*/}
      <Row>
        <Col md={6}>
          <FormGroup>
            <Label for="city">City</Label>
            <Input id="city" name="city" value={companyProfile.city} required onChange={handleChange} />
          </FormGroup>
        </Col>
        <Col md={4}>
          <FormGroup>
            <Label for="state">State</Label>
            <Input id="state" name="state" value={companyProfile.state} required onChange={handleChange} />
          </FormGroup>
        </Col>
        <Col md={2}>
          <FormGroup>
            <Label for="zip">Zip</Label>
            <Input id="zip" name="zip" value={companyProfile.zipcode} required onChange={handleChange} />
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col md={6}>
          <FormGroup>
            <Label for="email">Email</Label>
            <Input
              id="email"
              name="email"
              placeholder="info@example.com"
              type="email"
              value={companyProfile.email}
              onChange={handleChange}
            />
          </FormGroup>
        </Col>
        <Col md={6}>
          <FormGroup>
            <Label for="telephone">Telephone</Label>
            <Input
              id="telephone"
              name="telephone"
              tpye="tel"
              pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}"
              placeholder="543-222-1111"
              value={companyProfile.phone}
              onChange={handleChange}
            />
          </FormGroup>
        </Col>
      </Row>
      <FormGroup>
        <Label for="website">Website</Label>
        <Input
          id="website"
          name="website"
          type="url"
          placeholder="https://www.example.com"
          value={companyProfile.website}
          onChange={handleChange}
        />
      </FormGroup>
      <SpinnerButton type="submit" text="Update" isloading={isSaving} />
    </Form>
  );
}
