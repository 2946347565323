import { useState } from "react";
import { Form } from "react-router-dom";
import Select, { MultiValue, ActionMeta } from "react-select";
import { Label, Col, Input, Row } from "reactstrap";
import validationCssClassFormHelper from "src/utils";
import { useCreateNewStyle } from "../useHooks";
import { SpinnerButton } from "src/components/SpinnerButton";
import { toast } from "react-toastify";

type AddNewFenceStyleFormProps = {
  fenceTypeId: string;
  availableColors: { label: string; value: any }[];
  configuredStylesNames: string[];
  reloadStyles: () => void;
};

export default function AddNewFenceStyleForm(props: AddNewFenceStyleFormProps) {
  const { createStyle, isLoading, hasError: hasErrorSaving } = useCreateNewStyle();
  const [styleName, setStyleName] = useState("");
  const [colors, setColors] = useState<{ label: string; value: any }[]>();

  const [isStyleNameInValid, setStyleNameAsInValid] = useState<boolean | undefined>();
  const [isColorSelectionInValid, setColorSelectionAsInvalid] = useState<boolean | undefined>();

  const [hasFormSubmitAttempt, setFormSubmittedAttempt] = useState(false);

  function handleAddNewStyleForm(event: React.FormEvent<HTMLFormElement>): void {
    event.preventDefault();
    event.stopPropagation();
    let isSuccess = true;

    if (colors === undefined || colors.length === 0) {
      setColorSelectionAsInvalid(true);
      isSuccess = false;
    }
    if (styleName.trim() === "") {
      setStyleNameAsInValid(true);
      isSuccess = false;
    }

    if (!isSuccess) {
      return;
    }

    const colorIds = colors!.map((c) => String(c.value));

    createStyle(props.fenceTypeId, styleName, colorIds).then((r) => {
      if (r.data?.isSuccess) {
        toast.success("New fence style created.");
      }
      //setStyleInput({ value: "", isValid: undefined });
      props.reloadStyles();
      //reset to default
      setStyleName("");
      setColorSelectionAsInvalid(undefined);
      setStyleNameAsInValid(undefined);
      setColors([]);
    });
  }

  function handleOption(
    newValue: MultiValue<{ label: string; value: number }>,
    actionMeta: ActionMeta<{ label: string; value: number }>
  ): void {
    setColorSelectionAsInvalid(newValue.length === 0);
    setColors(
      newValue.map((x) => {
        return { label: x.label, value: x.value };
      })
    );
  }

  function handleStyleNameChange(event: React.ChangeEvent<HTMLInputElement>): void {
    const duplicateName = props.configuredStylesNames.some(
      (x) => x.toUpperCase().trim() === event.currentTarget.value.toUpperCase().trim()
    );
    const isEmpty = event.currentTarget.value.trim() === "";
    //if unique
    if (isEmpty || duplicateName) {
      setStyleNameAsInValid(true);
    } else {
      setStyleNameAsInValid(false);
    }

    setStyleName(event.currentTarget.value);
  }

  let customStyles = undefined;
  if (isColorSelectionInValid !== undefined && isColorSelectionInValid) {
    customStyles = {
      control: (provided: any) => ({
        ...provided,
        borderColor: "red",
      }),
    };
  }

  let isStyleNameCssValud = undefined;
  if (isStyleNameInValid !== undefined) {
    isStyleNameCssValud = !isStyleNameInValid;
  }

  return (
    <Form onSubmit={handleAddNewStyleForm}>
      <Row className="align-items-center">
        <Col>
          <Label for="styleName">Style</Label>
          <Input
            required
            value={styleName}
            className={validationCssClassFormHelper("", isStyleNameCssValud)}
            onChange={handleStyleNameChange}
          />
        </Col>
        <Col>
          <Label for="colorsSelect">Colors</Label>
          <Select
            isMulti
            options={props.availableColors || []}
            value={colors}
            onChange={handleOption}
            styles={customStyles}
          />
        </Col>
      </Row>
      <Row>
        <Col className="text-right">
          <SpinnerButton
            className="btn-primary"
            type="submit"
            style={{ marginTop: "10px" }}
            text="Add"
            isloading={isLoading}
          />
        </Col>
      </Row>
    </Form>
  );
}
/*return (
    <Form onSubmit={handleAddStyleForm}>
      <FormGroup row>
        <Label sm={2}>Style</Label>
        <Col sm={4}>
          <Input
            required
            value={styleName}
            className={validationCssClassFormHelper("", isStyleNameValid)}
            onChange={(e) => setStyleName(e.currentTarget.value)}
          />
        </Col>
       
          <Label sm={2} for="colors">
              Colors
            </Label>
       <Col>   
            <Select isMulti options={props.availableColors || []} />
          </FormGroup>
       </Col>
        <Col>
          <Button color="primary">Add</Button>
        </Col>
      </FormGroup>
    </Form>
  );
}*/
/* value={colors || []}
          onChange={handleOption}
          styles={customStyles} */
