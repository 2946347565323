import { getTypesResponse } from "./apiContracts";
import { FenceType } from "./models";

export function convertFromGetTypesResponse(from: getTypesResponse | undefined): Array<FenceType> {
  const to: Array<FenceType> = [];
  if (from === undefined) {
    return to;
  }

  from.types.forEach((x) => {
    to.push({
      id: x.id,
      name: x.name,
      isDisabled: x.isDisabled,
      availableColors: x.availableColors.map((a) => {
        return { label: a.name, value: a.id };
      }),
      styleSummaries: x.fenceStyleSummaries.map((s) => {
        return { id: s.id, name: s.name, variantCount: s.configuredVariantCount };
      }),
    });
  });
  return to;
}
/*
export function convertFromGetStylesReponse(from: getStylesResponse | undefined): Array<Style> {
  const to: Array<Style> = [];
  if (from === undefined) return to;

  from.styles.forEach((x) => {
    to.push({ id: x.id, name: x.name, variantCount: x.configuredVariantCount });
  });
  return to;
}
*/
