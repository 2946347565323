import { useEffect, useState } from "react";
import {
  getFenceTypes,
  createStyle as createStyleServiceCall,
  deleteStyle as deleteStyleServiceCall,
  disableFenceType as setFenceTypeDisabledServiceCall,
} from "./service";
import { FenceType } from "./models";

export function useGetFenceTypesList() {
  const [isLoading, setLoading] = useState(true);
  const [data, setItems] = useState<Array<FenceType>>([]);
  const [refresh, setRefresh] = useState(false);
  const [hasError, setError] = useState(false);

  useEffect(() => {
    getFenceTypes().then((f) => {
      setItems(f.data);
      setError(f.hasError);
    });
  }, [refresh]);

  const refreshItems = () => {
    setRefresh(!refresh);
  };

  return { data, hasError, refreshItems };
}

export function useGetFenceType(typeId: string) {
  const [isLoading, setLoading] = useState(true);
  const [data, setItem] = useState<FenceType>();
  const [refresh, setRefresh] = useState(false);
  const [hasError, setError] = useState(false);

  useEffect(() => {
    getFenceTypes().then((f) => {
      const fenceType = f.data.find((x) => x.id === typeId);
      setItem(fenceType);
      setError(f.hasError);
    });
  }, [refresh]);

  const refreshItems = () => {
    setRefresh(!refresh);
  };

  return { data, hasError };
}

/*
export function useGetFenceStyleList(fenceTypeId: string) {
  const [isLoading, setLoading] = useState(true);
  const [data, setItems] = useState<Array<Style>>([]);
  const [refresh, setRefresh] = useState(false);
  const [hasError, setError] = useState(false);

  useEffect(() => {
    getFenceStylesBy(fenceTypeId).then((f) => {
      setItems(f.data);
      setError(f.hasError);
    });
  }, [refresh]);

  const refreshItems = () => {
    setRefresh(!refresh);
  };

  return { data, hasError, refreshItems };
}
*/
export const useCreateNewStyle = () => {
  const [hasError, setError] = useState(false);
  const [isLoading, setLoading] = useState(false);

  const createStyle = (fenceTypeId: string, name: string, colorIds: string[]) => {
    setLoading(true); // Adding loading state
    return createStyleServiceCall(fenceTypeId, name, colorIds).then((r) => {
      setLoading(false); // Removing loading state
      setError(r.hasError);
      return r;
    });
  };

  return { createStyle, isLoading, hasError };
};

export const useDeleteStyle = () => {
  const [hasError, setError] = useState(false);
  const [isLoading, setLoading] = useState(false);

  const deleteStyle = (fenceTypeId: string, styleId: string) => {
    setLoading(true); // Adding loading state
    return deleteStyleServiceCall(fenceTypeId, styleId).then((r) => {
      setLoading(false); // Removing loading state
      setError(r.hasError);
      return r;
    });
  };

  return { deleteStyle, hasError };
};

export const useFenceTypeDisabled = () => {
  const [hasError, setError] = useState(false);
  const [isLoading, setLoading] = useState(false);

  const setDisabledStatus = (fenceTypeId: string, isDisabled: boolean) => {
    setLoading(true); // Adding loading state
    return setFenceTypeDisabledServiceCall(fenceTypeId, isDisabled).then((r) => {
      setLoading(false); // Removing loading state
      setError(r.hasError);
    });
  };

  return { setDisabledStatus, hasError };
};
