import * as React from "react";

import EstimatesTable, { Quote } from "./estimatesTable";
import EstimatesTablePagination from "./estimatesTablePagination";
import { NoEstimates } from "./noEstimates";
import { useGetEstimateList } from "./useHooks";

export default function EstimatesListPage() {
  const { data, currentPage, totalPages, pageSize, total, refreshEstimates, isLoading, hasError } =
    useGetEstimateList();
  //  const [currentPage, setCurrentPage] = React.useState<number>();

  //  const { data, loading, error, refetch } = useFetchData<GetQuotesResponse>("/53d3eb7a-1fa0-46e5-a58b-9175308492e5", {
  //    test: "x",
  //  });

  function handleGoToPage(page: number): void {
    refreshEstimates({ page: page.toString() });
  }
  if (isLoading) return <></>;
  if (data === undefined || data.length === 0) return <NoEstimates display={true} />;
  return (
    <>
      <EstimatesTable estimates={data} />
      <EstimatesTablePagination
        currentPage={currentPage}
        totalPages={totalPages}
        totalCount={total || 0}
        pageSize={pageSize}
        onGoToPage={handleGoToPage}
      />
    </>
  );
}
