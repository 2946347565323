import { customAxios } from "src/api/api";
import { convertEstimateDetailsfrom, convertEstimateListfrom } from "./transform";
import { EstimateSummary, IEstimateDetails } from "./models";
import { toast } from "react-toastify";

interface ApiResponse<T> {
  data: T;
  hasError: boolean;
}

export const getEstimateList = async (
  qs: string
): Promise<
  ApiResponse<{
    estimates: Array<EstimateSummary>;
    totalPages: number;
    currentPage: number;
    pageSize: number;
    total: number;
  }>
> => {
  let url = "/api/v1/estimates";
  try {
    if (qs !== "") {
      url = url + "?" + qs;
    }
    const response = await customAxios.get(url);
    const { estimates, totalPages, pageNumber, pageSize, total } = convertEstimateListfrom(response.data);
    return {
      data: { estimates: estimates, totalPages: totalPages, currentPage: pageNumber, pageSize, total },
      hasError: false,
    };
  } catch (err) {
    toast.error("Failed to get list of estimates.");
    console.log(err);
  }
  return { data: { estimates: [], totalPages: 0, currentPage: 0, pageSize: 0, total: 0 }, hasError: true };
};

export const getEstimateDetails = async (id: string): Promise<ApiResponse<IEstimateDetails | undefined>> => {
  let url = `/api/v1/estimates/${id}`;
  try {
    const response = await customAxios.get(url);
    const data = convertEstimateDetailsfrom(response.data);
    return { data: data, hasError: false };
  } catch (err) {
    toast.error("Failed to load estimate details");
    console.log(err);
  }
  return { data: undefined, hasError: true };
};

export const dispositionEstimate = async (id: string, reason: string): Promise<ApiResponse<undefined>> => {
  let url = `/api/v1/estimates/${id}?reasoncode=${reason}`;
  try {
    const response = await customAxios.delete(url);

    return { data: undefined, hasError: false };
  } catch (err) {
    toast.error("Failed to disposition estimate.");
    console.log(err);
  }
  return { data: undefined, hasError: true };
};
