import * as React from "react";

export function NoStyles(props: { display: boolean }) {
  if (!props.display) return <></>;
  return (
    <div
      className="border d-flex align-items-center justify-content-center"
      style={{ height: "200px", width: "100%", marginBottom: "20px" }}
    >
      <span>Add A New Fence Style</span>
    </div>
  );
}
