import { Form } from "react-router-dom";
import { Row, Col, FormGroup, Label, Input } from "reactstrap";
import { SpinnerButton } from "src/components/SpinnerButton";
import { useGetUserProfile, useSaveUserPrifile } from "./useHooks";
import { toast } from "react-toastify";

export function UserInformationForm() {
  const { data: userProfile, setItems } = useGetUserProfile();

  const { updateUserProfile, hasError: hasProfileError, isSaving } = useSaveUserPrifile();

  function handleUserProfileChange(event: React.ChangeEvent<HTMLInputElement>): void {
    switch (event.currentTarget.name) {
      case "firstName":
        userProfile.firstName = event.currentTarget.value;
        break;
      case "lastName":
        userProfile.lastName = event.currentTarget.value;
        break;
      default:
        break;
    }
    setItems({ ...userProfile });
  }

  function handleFormSubmit(event: React.FormEvent<HTMLFormElement>): void {
    updateUserProfile(userProfile.firstName, userProfile.lastName).then((r) => {
      toast.success("User profile updated.");
    });
  }

  return (
    <Form onSubmit={handleFormSubmit}>
      <Row>
        <Col>
          <FormGroup>
            <Label for="firstName">First Name</Label>
            <Input
              id="firstName"
              name="firstName"
              value={userProfile.firstName}
              required
              onChange={handleUserProfileChange}
            />
          </FormGroup>
        </Col>
        <Col>
          <FormGroup>
            <Label for="lastName">Last Name</Label>
            <Input
              id="lastName"
              name="lastName"
              value={userProfile.lastName}
              required
              onChange={handleUserProfileChange}
            />
          </FormGroup>
        </Col>
      </Row>
      <FormGroup>
        <Label for="email">Email</Label>
        <Input id="email" disabled name="email" value={userProfile.email} />
      </FormGroup>

      <SpinnerButton text={"Update"} isloading={isSaving} type="submit" />
    </Form>
  );
}
