import { customAxios } from "src/api/api";
import { FenceType } from "./models";
import { convertFromGetTypesResponse } from "./transform";
import { toast } from "react-toastify";

interface ApiResponse<T> {
  data: T;
  hasError: boolean;
}

export const getFenceTypes = async (): Promise<ApiResponse<Array<FenceType>>> => {
  const url = "/api/v1/catalog/types";
  try {
    const response = await customAxios.get(url);
    return { data: convertFromGetTypesResponse(response.data), hasError: false };
  } catch (err) {
    toast.error("Failed to get fence types.");
    console.log(err);
  }
  return { data: [], hasError: true };
};
/*
export const getFenceStylesBy = async (fenceTypeId: string): Promise<ApiResponse<Array<Style>>> => {
  const url = `/api/v1/catalog/types/${fenceTypeId}`;
  try {
    const response = await customAxios.get(url);
    return { data: convertFromGetStylesReponse(response.data), hasError: false };
  } catch (err) {
    toast.error("Failed to get fence style.");
    console.log(err);
  }
  return { data: [], hasError: true };
};
*/
export const createStyle = async (
  fenceTypeId: string,
  name: String,
  colorIds: string[]
): Promise<ApiResponse<{ isSuccess: boolean } | undefined>> => {
  const url = `/api/v1/catalog/types/${fenceTypeId}/styles`;
  try {
    const response = await customAxios.post(url, { name: name, colorIds: colorIds });

    return { data: { isSuccess: true }, hasError: false };
  } catch (err) {
    toast.error("Failed to create a new fence style.");
    console.log(err);
  }
  return { data: undefined, hasError: true };
};

export const deleteStyle = async (
  fenceTypeId: string,
  styleId: String
): Promise<ApiResponse<{ isSuccess: boolean } | undefined>> => {
  const url = `/api/v1/catalog/types/${fenceTypeId}/styles/${styleId}`;
  try {
    const response = await customAxios.delete(url);

    return { data: { isSuccess: true }, hasError: false };
  } catch (err) {
    toast.error("Failed to delete a fence style.");
    console.log(err);
  }
  return { data: undefined, hasError: true };
};

export const disableFenceType = async (fenceTypeId: string, isDisabled: boolean): Promise<ApiResponse<undefined>> => {
  const url = `/api/v1/catalog/types/${fenceTypeId}`;
  try {
    const response = await customAxios.put(url, { isDisabled: isDisabled });
    if (isDisabled) {
      toast.done("Fence type disabled successfully.");
    } else {
      toast.done("Fence type enabled successfully.");
    }

    return { data: undefined, hasError: false };
  } catch (err) {
    toast.error("Failed to update a fence type status.");
    console.log(err);
  }
  return { data: undefined, hasError: true };
};
