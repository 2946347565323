import { setToken } from "src/api/token";
import * as React from "react";
import { useNavigate } from "react-router";
import { Button, Form, Input, Label } from "reactstrap";
import { useLogin } from "./useHooks";
import { SpinnerButton } from "src/components/SpinnerButton";
import { useSearchParams } from "react-router-dom";

export default function LoginPage() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [userEmail, setUserEmail] = React.useState("");
  const [userPassword, setUserPassword] = React.useState("");
  const { login, hasError, isLoading } = useLogin();

  const handleFormSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    login(userEmail, userPassword).then((r) => {
      if (!r.hasError) {
        setTimeout(() => {
          const returnUrl = searchParams.get("returnUrl") || "/";
          if (returnUrl.startsWith("/")) {
            //FIXME: improve redirect injection attack defense
            navigate(returnUrl);
          }
        }, 900); //FIXME: hacky
      }
    });
  };
  return (
    <>
      <div className="container col-xl-10 col-xxl-8 px-4 py-5">
        <div className="row align-items-center g-lg-5 py-5">
          <div className="col-lg-7 text-center text-lg-start">
            <h1 className="display-4 fw-bold lh-1 mb-3">Company Portal</h1>
            <p className="col-lg-10 fs-4"></p>
          </div>
          <div className="col-md-10 mx-auto col-lg-5">
            <ValidationErrors didLoginFail={hasError} />
            <Form
              className="p-4 p-md-5 border rounded-3 bg-light"
              data-bitwarden-watching="1"
              onSubmit={handleFormSubmit}
            >
              <div className="form-floating mb-3">
                <Input
                  type="email"
                  className="form-control"
                  placeholder="name@example.com"
                  required={true}
                  onChange={(e) => setUserEmail(e.currentTarget.value)}
                />
                <Label for="floatingInput">Email address</Label>
              </div>
              <div className="form-floating mb-3">
                <Input
                  type="password"
                  className="form-control"
                  placeholder="Password"
                  required={true}
                  onChange={(e) => setUserPassword(e.currentTarget.value)}
                />
                <Label for="floatingPassword">Password</Label>
              </div>
              {/*  <div className="checkbox mb-3">
                <label>
                  <Input type="checkbox" value="remember-me" /> Remember me
                </label>
              </div> */}

              <SpinnerButton
                text="Login"
                spinnerText="Loading"
                isloading={isLoading}
                className="w-100"
                size="lg"
                color="primary"
                type="submit"
              />
            </Form>
            <div className="text-center">
              <a href="/register">Register a new account</a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

function ValidationErrors(props: { didLoginFail: boolean }) {
  if (!props.didLoginFail) return <></>;
  return (
    <div
      className="border d-flex align-items-center justify-content-left"
      style={{ width: "100%", marginBottom: "20px", paddingTop: "10px", background: "#ffbab3" }}
    >
      <ul>
        <li>Username or password is incorrect.</li>
      </ul>
    </div>
  );
}
