export function NoEstimates(props: { display: boolean }) {
  if (!props.display) return <></>;
  return (
    <div
      className="border d-flex align-items-center justify-content-center"
      style={{ height: "400px", width: "100%", marginBottom: "20px" }}
    >
      <span>No Estimates yet :(</span>
    </div>
  );
}
