import * as React from "react";
import { useNavigate } from "react-router";
import {
  Button,
  Collapse,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Form,
  Nav,
  NavItem,
  NavLink,
  Navbar,
  NavbarBrand,
  NavbarText,
  NavbarToggler,
  UncontrolledDropdown,
} from "reactstrap";
import { ILoggedInUser } from "src/auth";
import { ReactComponent as Logo } from "src/icons/fence-svgrepo-com.svg";

type Props = {
  user?: ILoggedInUser;
  singout: () => void;
};
export default function Header(props: Props) {
  const navigate = useNavigate();
  const handleSignOut = (e: React.MouseEvent) => {
    e.preventDefault();
    props.singout();
  };
  console.log(props.user?.name);
  const [isOpen, setIsOpen] = React.useState(false);
  return (
    <Navbar color="light" light expand="md">
      <NavbarBrand href="/">
        {" "}
        <Logo
          style={{
            height: 40,
            width: 40,
          }}
        />
        FenceBuildr
      </NavbarBrand>
      <NavbarToggler
        onClick={() => {
          setIsOpen(!isOpen);
        }}
      />
      <Collapse isOpen={isOpen} navbar>
        <Nav navbar>
          <NavItem>
            <NavLink href="/estimates">Estimates</NavLink>
          </NavItem>
          <NavItem>
            <NavLink href="/catalog">Catalog</NavLink>
          </NavItem>
          <NavItem>
            <NavLink href="/profile/company">Company</NavLink>
          </NavItem>
          <UncontrolledDropdown nav inNavbar>
            <DropdownToggle nav caret>
              Settings
            </DropdownToggle>
            <DropdownMenu end>
              <DropdownItem onClick={(e) => navigate("/settings/user")}>User</DropdownItem>
              <DropdownItem onClick={(e) => navigate("/settings/portal")}>Portal</DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
        </Nav>
        <NavbarText className="ms-auto"> {props.user?.name} </NavbarText>
        <NavbarText>
          <Button outline style={{ marginLeft: "5px" }} onClick={handleSignOut}>
            Logout
          </Button>
        </NavbarText>
      </Collapse>
    </Navbar>
  );
}
/*
  return (
    <div>
      <Navbar>
        <NavbarBrand href="/">
          <Logo
            style={{
              height: 40,
              width: 40,
            }}
          />
          FenceBuildr
        </NavbarBrand>

        <Nav className="me-auto" navbar>
        
        </Nav>
        <NavbarText>
          {props.user && (
            <>
              <p>{props.user.name}</p> <Button onClick={handleSignOut}>Logout</Button>
            </>
          )}
        </NavbarText>
      </Navbar>
    </div>
  );
}*/
