import { useEffect, useState } from "react";
import { getPortalSettings, getUserProfileInformation, updateUserPassword, updateUserProfile } from "./service";
import { PortalSettings, UserProfile } from "./models";

export function useGetUserProfile() {
  const [isLoading, setLoading] = useState(false);
  const [data, setItems] = useState<UserProfile>({ firstName: "", lastName: "", email: "" });
  const [hasError, setError] = useState(false);
  const [refersh, setRefresh] = useState(false);

  useEffect(() => {
    setLoading(true);
    getUserProfileInformation().then((r) => {
      if (!r.hasError) {
        setItems(r.data!);
      }
      setError(r.hasError);
      setLoading(false);
    });
  }, [refersh]);

  const refreshUserProfile = () => {
    setRefresh(!refersh);
  };

  return { data, isLoading, setItems };
}

export function useSaveUserPrifile() {
  const [isSaving, setSaving] = useState(false);
  const [hasError, setError] = useState(false);

  const saveUserProfile = (firstName: string, lastName: string) => {
    setSaving(true); // Adding loading state
    return updateUserProfile(firstName, lastName).then((r) => {
      setSaving(false); // Removing loading state
      setError(r.hasError);
    });
  };
  return { updateUserProfile, hasError, isSaving };
}

export function useSaveUserPasswords() {
  const [isSaving, setSaving] = useState(false);
  const [hasError, setError] = useState(false);

  const saveUserPassword = (newPassword: string) => {
    setSaving(true); // Adding loading state
    return updateUserPassword(newPassword).then((r) => {
      setSaving(false); // Removing loading state
      setError(r.hasError);
    });
  };
  return { saveUserPassword, hasError, isSaving };
}
export function useGetPortalSettings() {
  const [isLoading, setLoading] = useState(false);
  const [data, setItems] = useState<PortalSettings>({ portalUrl: "", embeddJs: "" });
  const [hasError, setError] = useState(false);

  useEffect(() => {
    setLoading(true);
    getPortalSettings().then((r) => {
      if (!r.hasError) {
        setItems(r.data!);
      }
      setError(r.hasError);
      setLoading(false);
    });
  }, []);

  return { data, isLoading };
}
