import { customAxios } from "src/api/api";
import { Registration, RegistrationResult } from "./models";
import { toast } from "react-toastify";
import { setToken } from "src/api/token";

export interface ApiResponse<T> {
  data: T;
  hasError: boolean;
}

export const saveUserRegistration = async (
  profile: Registration
): Promise<ApiResponse<RegistrationResult | undefined>> => {
  let url = `/api/v1/account/register`;
  try {
    const response = await customAxios.post<RegistrationResult>(url, profile);
    if (response.data.isSuccess) {
      setToken(response.data.token);
    }

    return { data: response.data, hasError: false };
  } catch (err) {
    toast.error("Failed to save registration");
    console.log(err);
  }
  return { data: undefined, hasError: true };
};
